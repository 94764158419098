import React from "react";
import Modal from "react-bootstrap/Modal";
import { Component } from "react";
import { postApi } from "../../../services/api/requestApi";
import Spinner from "react-bootstrap/Spinner";
import "./Aoi.scss";

export default class ShowPolygons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aoi: props.aoi,
      polygons: {},
      showModal: props.showModal,
      setShowModal: props.setShowModal,
      reload: props.reload,
      loading: true,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount = async () => {
    let response = await postApi("/admin/aois/polygons/get", {
      aoi: this.state.aoi.aoi_name,
    });
    if (response.success) {
      this.setState({
        polygons: JSON.parse(response.data.polygons),
        loading: false,
      });
    }
  };

  handleClose = () => {
    this.state.setShowModal("ShowPolygons", false);
  };

  handleChange(e, type) {
    this.setState({ [type]: e.target.value });
  }

  renderPolygons() {
    let result = [];
    for (let key in this.state.polygons) {
      let subparts = [];
      let specialLayers = [];
      for (let subpart in this.state.polygons[key].Subparts) {
        subparts.push(subpart);
      }
      for (let specialLayer in this.state.polygons[key].SpecialLayers) {
        specialLayers.push(specialLayer);
      }
      result.push(
        <tr>
          <td>{this.state.polygons[key].ClearName}</td>
          <td>{subparts.join(", ")}</td>
          <td>{specialLayers.join(", ")}</td>
        </tr>
      );
    }

    return result;
  }

  render() {
    return (
      <>
        <Modal
          animation={false}
          show={this.state.showModal}
          onHide={this.handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Show Polygons</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading ? (
              <div className="spinner">
                <Spinner animation="border" variant="success" size="xl" />
              </div>
            ) : (
              <table>
                <tr>
                  <th>Name</th>
                  <th>Subparts</th>
                  <th>Special Layers</th>
                </tr>
                {this.renderPolygons()}
              </table>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <button
                className="btn btn-light"
                onClick={this.handleClose}
                type="button"
              >
                OK
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
