import React from "react";
import { Component } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Select from "react-select";

export default class AoiUtils extends Component {
  constructor(props, state) {
    super(props);
    this.state = state;
  }

  updateSelectedAois(selections) {
    this.setState({ selectedAois: selections });
  }

  updateSelectedMemberships(selections) {
    this.setState({ selectedMemberships: selections });
  }

  renderAois() {
    let aoiOptions = [];
    for (let index in this.state.availableAois) {
      for (let id in this.state.availableAois[index]) {
        let aoi = this.state.availableAois[index][id];
        aoiOptions.push({
          value: aoi.aoi_name,
          label: aoi.aoi_name,
          disabled: !aoi.has_polygons || aoi.scripts.length > 0,
        });
      }
    }

    return (
      <Form>
        <Row className="mb-3">
          <Form.Group as={Col} className="4">
            <Form.Label>Available Memberships</Form.Label>
            <Select
              id="selected-memberships"
              isMulti
              value={this.state.selectedMemberships}
              options={[
                { value: "Basic", label: "Basic" },
                { value: "Premium", label: "Premium" },
              ]}
              onChange={this.updateSelectedMemberships}
            />
          </Form.Group>
          <Form.Group as={Col} className="4">
            <Form.Label>Available Areas of Interest</Form.Label>
            <Select
              id="selected-aois"
              isMulti
              value={this.state.selectedAois}
              options={aoiOptions}
              onChange={this.updateSelectedAois}
              isOptionDisabled={(option) => option.disabled}
            />
          </Form.Group>
        </Row>
      </Form>
    );
  }
}
