import { BASE_URL } from "../../env.js";
import { getToken } from "../auth/tokenProvider";

export const getApi = async (url) => {
  let data;
  let token = await getToken();
  //todo:token validation
  try {
    data = await fetch(BASE_URL + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    });
  } catch (e) {
    return {
      success: false,
      data: null,
    };
  }
  if (data.status === 200) {
    data = await data.json();
    return {
      success: true,
      data: data,
    };
  }
  return {
    success: false,
    data: data,
  };
};

export const postApi = async (url, body) => {
  let data;
  let token = await getToken();
  //todo:token validation
  try {
    data = await fetch(BASE_URL + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify(body),
    });
  } catch (e) {
    return {
      success: false,
      data: null,
    };
  }
  if (data.status === 200) {
    data = await data.json();
    return {
      success: true,
      data: data,
    };
  }
  return {
    success: false,
    data: data,
  };
};

// const getToken = () => {
//     //TODO: Dynamic Token allocation
//     return 'Bearer ' +
//     'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIwYzk3Yjk2YjBiYmU3N2NhNGJhMTZhZDg4ZWY5YjJjYSIsImp0aSI6IjY0NmQyOWE2Y2EzNjJiYWI0Zjc2ZTYwNjI3MjU2ZDBhYTczYzA1NThhYzViMTQ2NTVlNmRlNjFlZTFiMWI0NGVkZGM0OWFlNzczY2UzMGRhIiwiaWF0IjoxNjM1NTM4MDc4LjkwNTk5NSwibmJmIjoxNjM1NTM4MDc4LjkwNjAwMiwiZXhwIjoxNjY3MDc0MDc4Ljg4NTUwOSwic3ViIjoiS2FydW5hIEFkbWluIiwic2NvcGVzIjpbXX0.XIsd6BmvUxJ8hyye2xXERUR6y65rmKj-SRv6C_quVIf-Dm063oqzdv9-uYtZUunSUbCJNKE2hu-ZLRSxT6fZf9BT0dXsVZkSRZEPJ2aWe7nwEW3Ll7-_mt7h3kn-fRmFeO_tZ_CKL2sV6eTHFncq9FKN1aAmL9H7z_bHvWkTsw7HO8x2k7jWoGJxecE_yYhxHaGkHCPfW8s64PkvCYvKKZ-SZViLqcimhxiOVUP0VNUstmxjZGT_J1UhKbFXr2CCq4u0nh6NFX1Y9fZi0MmsdQQ_PxZRbR2Q63-xl52ALwslCJTBCNbgRLYOU9YZoACI4u5HcaqbaK12F2liEeQmWQ'
//     ;
// }

//Server Token: eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0YzQ3NTI4ZGZiY2JlMTUxMDU1ZDg3NGNlNzQ5ZDI0MyIsImp0aSI6IjZmYjIyMTgyZmExNGQ2OTNjZTI4ZmVmY2FlM2FlNWMzZWRhYTAyYTUxMjkwM2ZkY2Q0NmNlNGMwZjM4MzI1NjkwODY5NjRjMDM3M2FiOGFjIiwiaWF0IjoxNjI1MTU1OTc0LjI1MzQzMSwibmJmIjoxNjI1MTU1OTc0LjI1MzQzNywiZXhwIjoxNjU2NjkxOTc0LjIzMDg5OCwic3ViIjoiS2FydW5hIEFkbWluIiwic2NvcGVzIjpbXX0.HY07uEka9AoOmJf28zxz9umm2vdsKxwyIQmHMjrMrMYww5D1ZSXZtHo42tJPbKROpvYDtjPbURKz2DlMPWZADD-qJwBCFhuXvqm2x5Kg49MWNYg3LnTgtqUzdAYwtEfFyUpBNGy6CHvu9hPD6vP5BWHb88mbAANrIG0AQoY2uUd7dnq2IsuPGU-fr-3NrjUIrIY2Z90hhPwyczR6AcPB0l_ySMcQLQDKKXiHH6zym1ZvZqSYq0w9nQ-TadaCbSI1SHBs-TzgrtTRJJwdI-7VvCBezJlEo4rIuEC2Ea8vvvC3yeSEDvi-p4ohX2AY3bjHM2yLJ0hCpeO539FqtNbXZg
