import { React, Component } from "react";
import Table from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Modal from "react-bootstrap/Modal";
import { postApi } from "../../../services/api/requestApi";
import { GEE_URL } from "../../../env";
import LoadingIcon from "../../../components/atoms/LoadingIcon";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { DateRangePicker } from "rsuite";
import Form from "react-bootstrap/Form";
import { AiOutlineReload } from "react-icons/ai";
import Button from "react-bootstrap/Button";
import Log from "./Log";

export default class Logs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      logs: [],
      images: {},
      showModal: props.showModal,
      setShowModal: props.setShowModal,
      reload: props.reload,
      loading: true,
      flagLoading: false,
      override: false,
      showLog: false,
    };

    this.loadData = this.loadData.bind(this);
    this.setShowLog = this.setShowLog.bind(this);
  }

  componentDidMount = async () => {
    this.loadData();
  };

  loadData = async () => {
    this.setState({ loading: true, flagLoading: false });
    let response = await postApi("/admin/icscripts/history", {
      id: this.props.currentElem.id,
      type: this.props.currentElem.ai ? "AI" : "ICScript"
    });
    if (response.success) {
      this.setState({
        logs: response.data.logs,
        images: response.data.images,
        error: "",
        loading: false,
      });
    } else {
      this.setState({
        error: "Couldn't retrieve logs.",
        loading: false,
      });
    }
  };

  setShowLog(show, logId) {
    this.setState({ showLog: show, currentLogId: logId });
  }

  handleClose = () => {
    this.state.setShowModal("Logs", false);
  };

  handleFlagged = async (flagged, id, uid, IC_name) => {
    this.setState({ flagLoading: true });
    await fetch(GEE_URL + "/flagImg", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        imgIds: [id],
        projectId: this.props.currentElem.project_id,
        IC_name: IC_name,
        flagged: flagged,
      }),
    })
      .then((response) => response.json())
      .then(async (data) => {
        if (data.success) {
          let response = await postApi("/admin/icscripts/image/update", {
            id: uid,
            flagged: flagged,
          });
          if (response.success) this.loadData();
          else
            this.setState({
              error: "There was an error in the backend.",
              flagLoading: false,
            });
        } else
          this.setState({
            error: "There was an error in the RE server.",
            flagLoading: false,
          });
      });
  };

  updateReviewed = async (reviewed, id) => {
    let url = "/admin/icscripts/image/update";
    let body = {
      id: id,
      reviewed: reviewed,
    };
    let response = await postApi(url, body);
    if (response.success) {
      this.loadData();
    } else {
      this.setState({ error: "An error occurred, please try again." });
    }
  };

  renderLogs() {
    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "executionDate",
        text: "Date",
        sort: true,
        sortFunc: (a, b, order) => {
          if (a == "N/A" && b == "N/A") return 0;
          else if (a == "N/A") {
            if (order == "asc") {
              return -1;
            } else return 1;
          } else if (b == "N/A") {
            {
              if (order == "asc") {
                return 1;
              } else return -1;
            }
          }

          let tempA = a.split(" ");
          let dateA = tempA[0];
          dateA = dateA.split("-");
          dateA =
            "20" + dateA[2] + "-" + dateA[1] + "-" + dateA[0] + "T" + tempA[1];

          let tempB = b.split(" ");
          let dateB = tempB[0];
          dateB = dateB.split("-");
          dateB =
            "20" + dateB[2] + "-" + dateB[1] + "-" + dateB[0] + "T" + tempB[1];
          if (order === "asc") {
            return Date.parse(dateA) - Date.parse(dateB);
          } else if (order === "desc") {
            return Date.parse(dateB) - Date.parse(dateA);
          }
        },
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      {
        dataField: "images",
        text: "Images",
        sort: true,
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      {
        dataField: "status",
        text: "Status",
        sort: true,
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      {
        dataField: "log",
        text: "",
        style: { textAlign: "center" },
      },
    ];
    let logData = [];
    this.state.logs.reverse().map((log, index) => {
      let remainingElems = log.images.length - 20;
      logData.push({
        executionDate: log.execution_date,
        images:
          log.images.slice(0, 8).join(", \n") +
          (remainingElems > 0 ? " + " + remainingElems + " images" : ""),
        status: log.status,
        log: (
          <button
            class="btn btn-light btn-sm"
            onClick={() => this.setShowLog(true, log.id)}
          >
            Show Log
          </button>
        ),
      });
    });

    return (
      <>
        <ToolkitProvider keyField="id" data={logData} columns={columns} search>
          {(props) => (
            <div>
              <div className="d-flex justify-content-between align-items-center">
                <SearchBar {...props.searchProps} />
                <Button variant="light" onClick={() => this.loadData()}>
                  <AiOutlineReload />
                </Button>
              </div>
              <hr />
              <Table
                {...props.baseProps}
                defaultSorted={[{ dataField: "executionDate", order: "desc" }]}
                pagination={paginationFactory({ sizePerPage: 10 })}
              />
            </div>
          )}
        </ToolkitProvider>
      </>
    );
  }

  renderImages() {
    let availableDates = Object.keys(this.state.images);

    const columns = [
      {
        dataField: "id",
        text: "id",
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      {
        dataField: "date",
        text: "Date",
        sort: true,
        sortFunc: (a, b, order) => {
          if (a == "N/A" && b == "N/A") return 0;
          else if (a == "N/A") {
            if (order == "asc") {
              return -1;
            } else return 1;
          } else if (b == "N/A") {
            {
              if (order == "asc") {
                return 1;
              } else return -1;
            }
          }

          let tempA = a.split(" ");
          let dateA = tempA[0];
          dateA = dateA.split("-");
          dateA =
            "20" + dateA[2] + "-" + dateA[1] + "-" + dateA[0] + "T" + tempA[1];

          let tempB = b.split(" ");
          let dateB = tempB[0];
          dateB = dateB.split("-");
          dateB =
            "20" + dateB[2] + "-" + dateB[1] + "-" + dateB[0] + "T" + tempB[1];
          if (order === "asc") {
            return Date.parse(dateA) - Date.parse(dateB);
          } else if (order === "desc") {
            return Date.parse(dateB) - Date.parse(dateA);
          }
        },
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      {
        dataField: "published_date",
        text: "Published date",
        sort: true,
        sortFunc: (a, b, order) => {
          if (a == "N/A" && b == "N/A") return 0;
          else if (a == "N/A") {
            if (order == "asc") {
              return -1;
            } else return 1;
          } else if (b == "N/A") {
            {
              if (order == "asc") {
                return 1;
              } else return -1;
            }
          }

          let tempA = a.split(" ");
          let dateA = tempA[0];
          dateA = dateA.split("-");
          dateA =
            "20" + dateA[2] + "-" + dateA[1] + "-" + dateA[0] + "T" + tempA[1];

          let tempB = b.split(" ");
          let dateB = tempB[0];
          dateB = dateB.split("-");
          dateB =
            "20" + dateB[2] + "-" + dateB[1] + "-" + dateB[0] + "T" + tempB[1];
          if (order === "asc") {
            return Date.parse(dateA) - Date.parse(dateB);
          } else if (order === "desc") {
            return Date.parse(dateB) - Date.parse(dateA);
          }
        },
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      {
        dataField: "flagged",
        text: "Accepted",
        sort: true,
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      {
        dataField: "reviewed",
        text: "Reviewed",
        sort: true,
        style: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
    ];
    let imageData = [];
    for (let key of Object.keys(this.state.images)) {
      for (let image of this.state.images[key]) {
        if ("selectedDates" in this.state) {
          let date = new Date(image.date);
          if (
            date.getTime() >= this.state.selectedDates[0].getTime() &&
            date.getTime() <= this.state.selectedDates[1].getTime()
          ) {
            imageData.push({
              id: image.pid,
              date: image.date,
              published_date: image.published_date,
              flagged: this.state.flagLoading ? (
                LoadingIcon(4)
              ) : (
                <>
                  <Form.Check
                    type={"checkbox"}
                    checked={!image.flagged}
                    onChange={() =>
                      this.handleFlagged(
                        !image.flagged,
                        image.pid,
                        image.id,
                        image.IC_name
                      )
                    }
                    disabled={!this.state.override}
                  />
                </>
              ),
              reviewed: (
                <>
                  <Form.Check
                    type={"checkbox"}
                    checked={image.reviewed}
                    onChange={() => {
                      this.updateReviewed(!image.reviewed, image.id);
                    }}
                  />
                </>
              ),
            });
          }
        }
      }
    }

    return (
      <>
        <div className="d-flex justify-content-between mt-4 ml-2">
          <DateRangePicker
            size="lg"
            placeholder="Select a date"
            ranges={[]}
            disabledDate={(date, selectDate) => {
              if (selectDate[0] < date && selectDate[1] > date) {
                return false;
              }
              for (let availableDate of availableDates) {
                let splitDate = availableDate.split("-");
                let formattedDate = new Date(
                  splitDate[2] +
                    "-" +
                    splitDate[1] +
                    "-" +
                    splitDate[0] +
                    "T00:00:00"
                );
                if (date.toDateString() === formattedDate.toDateString()) {
                  return false;
                }
              }
              return true;
            }}
            onChange={(value) => {
              this.setState({
                selectedDates: [
                  new Date(value[0].setHours(0, 0, 0, 0)),
                  new Date(value[1].setHours(23, 59, 59, 999)),
                ],
              });
            }}
          />
          <button
            className="btn btn-dark"
            onClick={() => this.setState({ override: !this.state.override })}
          >
            Override
          </button>
        </div>
        <Table
          keyField="id"
          data={imageData}
          columns={columns}
          pagination={paginationFactory({ sizePerPage: 10 })}
        />
      </>
    );
  }

  render() {
    return (
      <>
        (
        <Modal
          size="lg"
          animation={false}
          show={this.state.showModal}
          onHide={this.handleClose}
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Tabs>
              <TabList>
                <Tab>Image Overview</Tab>
                <Tab>Logs</Tab>
              </TabList>
              <TabPanel>
                {this.state.loading ? LoadingIcon() : this.renderImages()}
              </TabPanel>
              <TabPanel>
                {this.state.loading ? (
                  LoadingIcon()
                ) : this.state.showLog ? (
                  <Log
                    id={this.state.currentLogId}
                    showModal={this.state.showLog}
                    setShowModal={this.setShowLog}
                  />
                ) : (
                  this.renderLogs()
                )}
              </TabPanel>
            </Tabs>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
        )
      </>
    );
  }
}
