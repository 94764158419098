import React from 'react';
import { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import { postApi } from "../../../../services/api/requestApi";

export default class ChangeMembership extends Component{

    constructor (props) {
        super (props);
        this.state = {
            showModal: props.showModal,
            setShowModal: props.setShowModal,
            currentMembership: props.currentMembership,
            reload: props.reload
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose = () => {
        this.state.setShowModal('ChangeMembership', false);
    }

    handleSubmit = async() => {
        let url = '/admin/user/update';
        let body = {
            'id': this.props.id,
            'membership': document.getElementById('membership-select').value
        }
        let response = await postApi(url, body);
        if (response !== false) {
            this.handleClose();
            this.state.reload();
        }
    }

    render () {
        return (
            <>
                <Modal animation={false} show={this.state.showModal} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Change Membership</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        
                            <div className="form-group">
                                <p>The current membership is <b>{this.state.currentMembership}</b></p>
                            </div>
                            <div className="form-group">
                                <label>Choose a new membership:</label>
                                <select id='membership-select' className="form-control">
                                    <option value="Basic">Basic</option>
                                    <option value="Premium">Premium</option>
                                </select>
                            </div>
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            <button className="btn btn-success mr-3" type="button"onClick={this.handleSubmit} >Confirm</button>
                            <button className="btn btn-danger" type="button" onClick={this.handleClose}>Cancel</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}