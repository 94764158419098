import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Component } from 'react';
import { postApi } from "../../../../services/api/requestApi";

export default class ChangeAoi extends Component {

    constructor (props) {
        super(props);
        this.state = {
            newPassword: "",
            repeatNewPassword: "",
            showModal: props.showModal,
            setShowModal: props.setShowModal,
            source: props.source,
            reload: props.reload
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose = () => {
        this.state.setShowModal('ChangePassword', false);
    }

    handleChange (type, e) {
        this.setState({[type]: e.target.value});
    }

    handleSubmit = async() => {
        if (this.state.newPassword === this.state.repeatNewPassword) {
            let url = '/admin/user/update';
            let body = {
                'id': this.props.id,
                'password': this.state.newPassword
            }
            let response = await postApi(url, body);
            if (response !== false) {
                this.handleClose();
                this.state.reload();
            }
        }
        else alert("The password doesn't match");
    }

    render () {
        return (
            <>
                <Modal animation={false} show={this.state.showModal} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Change password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <div className="form-group">
                        <label>New password:</label>
                        <input type="password" className="form-control" value={this.state.newPassword} onChange={(e) => this.handleChange ('newPassword', e)}/>
                    </div>
                    <div className="form-group">
                        <label>Repeat new password:</label>
                        <input type="password" className="form-control" value={this.state.repeatNewPassword} onChange={(e) => this.handleChange ('repeatNewPassword', e)}/>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            <button className="btn btn-success mr-3" onClick={this.handleSubmit} type="button">Change</button>
                            <button className="btn btn-danger" onClick={this.handleClose} type="button">Cancel</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}