import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";
import "./NavigationBar.scss";
import { logout } from "../../services/auth/authProvider";
import { getApi } from "../../services/api/requestApi";

export default function NavigationBar() {
  const [userId, setUserId] = useState("");

  useEffect(() => {
    const getUserId = async () => {
      let response = await getApi("/api/user/get");
      if (response.success) {
        setUserId(response.data.id);
      }
      else {
        console.log("error!")
      }
    };
    getUserId();
  }, []);

  return (
    <>
      <Navbar collapseOnSelect expand="md">
        <Navbar.Brand href="/admin">Karuna</Navbar.Brand>
        <Navbar.Toggle aria-controls="navigation-bar" />
        <Navbar.Collapse id="navigation-bar">
          <Nav id="admin-navbar" className="ml-auto pt-1">
            <Nav.Link as={Link} to="/admin/users" className="nav-link">
              Users
            </Nav.Link>
            <Nav.Link as={Link} to="/admin/scripts" className="nav-link">
              Scripts
            </Nav.Link>
            <Nav.Link as={Link} to="/admin/aois" className="nav-link">
              AoI
            </Nav.Link>
            <Nav.Link as={Link} to="/admin/icscripts" className="nav-link">
              IC-Scripts
            </Nav.Link>
            <Nav.Link as={Link} to="/gee" className="nav-link">
              GEE
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={`/admin/user-profil/${userId}`}
              className="nav-link"
            >
              My Profile
            </Nav.Link>
            <Nav.Link as={Link} to="/login" onClick={logout}>
              Logout
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}
