import React, { useState, useRef, useEffect } from "react";
import "./UserView.scss";
import SidePanel from "../../components/molecules/side-panel/SidePanel";
import ColourScheme from "../../components/atoms/ColourScheme";
import { getApi, postApi } from "../../services/api/requestApi";
import ee from "@google/earthengine";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { logout } from "../../services/auth/authProvider";
import Tour from "reactour";
import ChangeLanguage from "../../components/atoms/ChangeLanguage";
import { Translate, I18n, setLocale, translate } from "react-i18nify";
import Dropdown from "react-bootstrap/Dropdown";

export default function UserView() {
  const [isLoading, setIsLoading] = useState({});
  const [geoName, setGeoName] = useState(translate("sidepanel.choose"));
  const [subpart, setSubpart] = useState("");
  const [allGeos, setAllGeos] = useState([]);
  const [buttonActive, setButtonActive] = useState(
    <Translate value="sidepanel.show_all" />
  );
  const [geometrics, setGeometrics] = useState({});
  const [activeGeometric, setActiveGeometric] = useState("");
  const [activeLayer, setActiveLayer] = useState("");
  const [map, setMap] = useState(null);
  const [initialOptions, setInitialOptions] = useState({
    tilt: 0,
    mapTypeId: window.google.maps.MapTypeId.SATELLITE,
    streetViewControl: false,
    rotateControl: false,
  });
  const [chartData, setChartData] = useState({});
  const [chartDates, setChartDates] = useState([]);
  const [activeChartData, setActiveChartData] = useState({});
  const [chartLoading, setChartLoading] = useState(false);
  const [polygons, setPolygons] = useState({});
  const [activeSpecialLayers, setActiveSpecialLayers] = useState({});
  const [charts, setCharts] = useState({});
  const [script, setScript] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [availableAois, setAvailableAois] = useState([]);
  const [showControlPanel, setShowControlPanel] = useState(false);
  const [currentAoi, setCurrentAoi] = useState(null);
  const [showTour, setShowTour] = useState(false);
  const [bicubic, setBicubic] = useState(false);
  const [increaseContrast, setIncreaseContrast] = useState(false);
  const [variance, setVariance] = useState(false);
  const [chartsActivated, setChartsActivated] = useState(true);
  const [aiActivated, setAiActivated] = useState(false);
  const [lang, setLang] = useState("en");
  const [settings, setSettings] = useState({});
  const [activeTime, setActiveTime] = useState(Date.now());
  const [baseMap, setBaseMap] = useState("");
  const [opacity, setOpacity] = useState(1.0);
  const [isAdmin, setIsAdmin] = useState(false);
  const [moistureActive, setMoistureActive] = useState(false);
  const [aiEnabled, setAiEnabled] = useState(false);
  const [tabActive, setTabActive] = useState("spectral");
  let ref = useRef();
  let intervalId = "";

  const getSteps = function () {
    let steps = [
      {
        selector: '[intro-tour="reactour__datepicker"]',
        content: <Translate value="tour.tour_datepicker" />,
      },
      {
        selector: '[intro-tour="reactour__geopicker"]',
        content: <Translate value="tour.tour_geopicker" />,
      },
      {
        selector: '[intro-tour="reactour__chart"]',
        content: <Translate value="tour.tour_chart" />,
      },
      {
        selector: '[intro-tour="reactour__bicubic"]',
        content: <Translate value="tour.tour_bicubic" />,
      },
      {
        selector: '[intro-tour="reactour__increase_contrast"]',
        content: <Translate value="tour.tour_increase_contrast" />,
      },
      {
        selector: '[intro-tour="reactour__variance"]',
        content: <Translate value="tour.tour_variance" />,
      },
      {
        selector: '[intro-tour="reactour__layers"]',
        content: <Translate value="tour.tour_evi" />,
      },
      {
        selector: '[intro-tour="reactour__layers"]',
        content: <Translate value="tour.tour_gndvi" />,
      },
      {
        selector: '[intro-tour="reactour__layers"]',
        content: <Translate value="tour.tour_ndvi" />,
      },
      {
        selector: '[intro-tour="reactour__layers"]',
        content: <Translate value="tour.tour_rgb" />,
      },
      {
        selector: '[intro-tour="reactour__layers"]',
        content: <Translate value="tour.tour_smi" />,
      },
      {
        selector: '[intro-tour="reactour__layers"]',
        content: <Translate value="tour.tour_wqi" />,
      },
      {
        selector: '[intro-tour="reactour__layers"]',
        content: <Translate value="tour.tour_diff_mean" />,
      },
      {
        selector: '[intro-tour="reactour__reset"]',
        content: <Translate value="tour.tour_reset" />,
      },
    ];

    if (chartsActivated) {
      steps.push({
        selector: '[intro-tour="reactour__charts"]',
        content: <Translate value="tour.tour_charts" />,
      });
      steps.push({
        selector: '[intro-tour="reactour__magnify_charts"]',
        content: <Translate value="tour.tour_magnify_charts" />,
      });
      steps.push({
        selector: '[intro-tour="reactour__menu"]',
        content: <Translate value="tour.tour_menu" />,
      });
    } else {
      steps.push({
        selector: '[intro-tour="reactour__menu"]',
        content: <Translate value="tour.tour_menu" />,
      });
    }
    return steps;
  };

  const setMapOptions = async (options) => {
    map.setOptions(options);
  };

  // Display an ee image on the google map.
  const showEEImage = async (
    initialize = false,
    layerButton = activeLayer,
    activeGeo = activeGeometric,
    savedGeos = geometrics,
    isSubpart = subpart != null,
    sp = subpart,
    activeSpecLayers = activeSpecialLayers,
    basemap = baseMap
  ) => {
    let isSpecialLayer = !(
      (layerButton in settings.layers) |
      layerButton.startsWith("diff_") |
      layerButton.startsWith("median_")
    );
    if (!isSpecialLayer) resetMap();
    let layersTileSources = [];
    let specialLayersTileSources = [];

    try {
      //Note: it is important that the variable is named mapid!
      //Check if the layer selected is a special layer
      if (layerButton != "") {
        if (!isSpecialLayer) {
          const mapids = isSubpart
            ? savedGeos[activeGeo].subparts[sp][layerButton]
            : savedGeos[activeGeo][layerButton];
          for (let mapid of mapids) {
            if (mapid != undefined) {
              layersTileSources.push(
                new ee.layers.EarthEngineTileSource({
                  mapid: mapid,
                })
              );
            } else {
              throw new Error("Map ID is not defined");
            }
          }
          setButtonActive(
            isSubpart
              ? polygons[activeGeo].Subparts[sp].ClearName
              : polygons[activeGeo].ClearName
          );
        } else {
          const mapids = savedGeos[activeGeo][activeLayer];
          for (let mapid of mapids) {
            if (mapid != undefined) {
              layersTileSources.push(
                new ee.layers.EarthEngineTileSource({
                  mapid: mapid,
                })
              );
            } else {
              throw new Error("Map ID is not defined");
            }
          }
          activeSpecLayers[layerButton] = savedGeos[activeGeo][layerButton];
          setButtonActive(
            polygons[activeGeo].SpecialLayers[layerButton.replaceAll("_", " ")]
              .ClearName
          );
          setActiveSpecialLayers(activeSpecLayers);
        }
      }

      //Add all already selected special layers to the map
      for (let activeSpecLayer in activeSpecLayers) {
        if (activeSpecLayer in savedGeos[activeGeo]) {
          const mapids = activeSpecLayers[activeSpecLayer];
          for (const mapid of mapids) {
            if (mapid != undefined) {
              specialLayersTileSources.push(
                new ee.layers.EarthEngineTileSource({
                  mapid,
                })
              );
            } else {
              throw new Error("Map ID is not defined");
            }
          }
        }
      }

      if (layerButton != "" && !isSpecialLayer) setActiveLayer(layerButton);

      //overlay the images over the map
      for (let ts of layersTileSources) {
        map.overlayMapTypes.insertAt(2, new ee.layers.ImageOverlay(ts));
      }
      for (let ts of specialLayersTileSources) {
        map.overlayMapTypes.insertAt(3, new ee.layers.ImageOverlay(ts));
      }

      try {
        if (basemap != "" && basemap != undefined) {
          const bm = new ee.layers.EarthEngineTileSource({ mapid: basemap });
          map.overlayMapTypes.insertAt(1, new ee.layers.ImageOverlay(bm));
          map.overlayMapTypes.getAt(1).setOpacity(opacity);
        }
      } catch (e) {
        console.log(e);
      }

      if (layerButton != "") {
        if (activeGeo.toUpperCase().includes("ALL") || initialize) {
          await setMapOptions(initialOptions);
        } else {
          await setMapOptions({
            ...initialOptions,
            center: {
              lng: isSubpart
                ? polygons[activeGeo].Subparts[sp].Center.lng
                : polygons[activeGeo].Center.lng,
              lat: isSubpart
                ? polygons[activeGeo].Subparts[sp].Center.lat
                : polygons[activeGeo].Center.lat,
            },
            zoom: 17,
            maxZoom: 18,
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const logTime = async (activityTime, aoi = currentAoi) => {
    let url = "/api/user/session";
    let date = new Date();
    let offset = date.getTimezoneOffset() / 60;
    let body = {
      offset: offset,
      activity_time: activityTime % 3600,
      aoi: aoi,
    };
    await postApi(url, body);
  };

  useEffect(() => {
    console.log("Initialize...");

    const getData = async () => {
      let response = await getApi("/api/user/scripts");
      if (response.success) {
        setAvailableAois(response.data.available_aois);
        if (response.data.current_aoi !== null) {
          //Set the initial position
          let settings = JSON.parse(response.data.settings);
          if ("image_collection_moisture" in settings.general) {
            if (
              settings.general.image_collection_moisture !== "" &&
              response.data.smi_enabled
            ) {
              setMoistureActive(true);
              if (response.data.smi) setTabActive("moisture");
            }
          }
          setAiEnabled(response.data.ai_enabled ? true : false);
          setSettings(settings);

          setIsAdmin(response.data.is_admin);

          if ("basemap" in settings.general) {
            setOpacity(parseInt(settings.general.basemap.opacity) / 100);
          }

          //Store the polygons
          let polys = JSON.parse(response.data.polygons);
          setPolygons(polys);

          setAllGeos(Object.keys(polys));

          //Store the needed buttons
          let layers = JSON.parse(response.data.layers);
          let specialLayers = JSON.parse(response.data.specialLayers);
          let charts = JSON.parse(response.data.charts);

          let temp = {};
          for (let layer of layers) {
            temp = {
              ...temp,
              ...layer,
            };
          }

          temp = {};
          for (let chart of charts) {
            temp = {
              ...temp,
              ...chart,
            };
          }
          setCharts(temp);

          setScript(response.data.script);
          setCurrentAoi(response.data.current_aoi);
          setShowTour(response.data.first_login);
          setChartsActivated(response.data.first_login);
          let bicubicSet = response.data.bicubic;
          let increaseContrastSet = response.data.increaseContrast;
          let varianceSet = response.data.variance;
          bicubicSet == null ? setBicubic(false) : setBicubic(bicubicSet);
          increaseContrastSet == null
            ? setIncreaseContrast(false)
            : setIncreaseContrast(increaseContrastSet);
          varianceSet == null ? setVariance(false) : setVariance(varianceSet);

          let options = {
            ...initialOptions,
            center: {
              lng: settings.general.center.lng,
              lat: settings.general.center.lat,
            },
            zoom: settings.general.center.zoom,
            minZoom: settings.general.center.zoom - 1,
          };
          setInitialOptions(options);
          setMap(new window.google.maps.Map(ref.current, options));
        } else {
          setShowControlPanel(true);
        }

        if (!(response.data.locale == undefined)) {
          setLang(response.data.locale);
          setLocale(response.data.locale);
        }

        let i = 0;
        //This logs the activity time every 15s
        intervalId = setInterval(() => {
          let activityTime =
            Math.floor((Date.now() - activeTime) / 1000) - i * 15;
          logTime(activityTime, response.data.current_aoi);
          i++;
        }, 15000);

        setDataLoaded(true);
      } else {
        try {
          console.log("Error " + response.data.status);
        } catch (e) {
          console.log(e.message);
        }
      }
    };
    getData();
  }, []);

  const resetMap = async () => {
    const bm = map.overlayMapTypes[1];
    map.overlayMapTypes.clear();
    map.overlayMapTypes.insertAt(1, bm);
  };

  window.onunload = () => {
    clearInterval(intervalId);
  };

  const addDropdownItems = function () {
    let items = [];
    if (currentAoi === null)
      items.push(
        <option value="select" disabled selected>
          {translate("menu.select")}
        </option>
      );
    availableAois.map((item) => {
      items.push(<option value={item}>{item}</option>);
    });
    return items;
  };

  const handleAoiChange = async () => {
    let newAoi = document.getElementById("aoi-select").value;
    let url = "/api/user/update";
    let body = {
      aoi: newAoi,
    };
    let response = await postApi(url, body);
    if (response !== false) {
      window.location.reload();
    }
  };

  const handleClose = function () {
    setShowControlPanel(false);
  };

  const openControlPanel = function () {
    setShowTour(false);
    setShowControlPanel(true);
  };

  const endTour = async () => {
    setShowTour(false);
    let url = "/api/user/update";
    let body = {
      firstLogin: false,
    };
    postApi(url, body);
  };

  const startTour = function () {
    setShowControlPanel(false);
    setShowTour(true);
  };

  const updateUser = async (
    bicubicSet = bicubic,
    increaseContrastSet = increaseContrast,
    varianceSet = variance,
    smiSelected = tabActive === "moisture"
  ) => {
    let url = "/api/user/update";
    let body = {
      increaseContrast: increaseContrastSet,
      bicubic: bicubicSet,
      variance: varianceSet,
      smiSelected,
    };
    await postApi(url, body);
  };

  const changeLanguage = async (lang) => {
    setLang(lang);
    setLocale(lang);

    let url = "/api/user/update";
    let body = {
      locale: lang,
    };
    await postApi(url, body);
  };

  return (
    <I18n
      render={() => (
        <>
          {(currentAoi !== null) | !dataLoaded ? (
            <>
              <SidePanel
                showEEImage={showEEImage}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                geoName={geoName}
                setGeoName={setGeoName}
                buttonActive={buttonActive}
                setButtonActive={setButtonActive}
                geometrics={geometrics}
                setGeometrics={setGeometrics}
                activeGeometric={activeGeometric}
                setActiveGeometric={setActiveGeometric}
                chartData={chartData}
                chartDates={chartDates}
                chartLoading={chartLoading}
                setChartLoading={setChartLoading}
                setChartDates={setChartDates}
                setChartData={setChartData}
                activeChartData={activeChartData}
                setActiveChartData={setActiveChartData}
                polygons={polygons}
                activeLayer={activeLayer}
                setActiveLayer={setActiveLayer}
                activeSpecialLayers={activeSpecialLayers}
                setActiveSpecialLayers={setActiveSpecialLayers}
                charts={charts}
                script={script}
                allGeos={allGeos}
                subpart={subpart}
                setSubpart={setSubpart}
                dataLoaded={dataLoaded}
                resetMap={resetMap}
                bicubic={bicubic}
                increaseContrast={increaseContrast}
                setBicubic={setBicubic}
                variance={variance}
                setVariance={setVariance}
                setIncreaseContrast={setIncreaseContrast}
                chartsActivated={chartsActivated}
                setChartsActivated={setChartsActivated}
                updateUser={updateUser}
                settings={settings}
                setBaseMap={setBaseMap}
                isAdmin={isAdmin}
                moistureActive={moistureActive}
                tabActive={tabActive}
                setTabActive={setTabActive}
                aiActivated={aiActivated}
                setAiActivated={setAiActivated}
                aiEnabled={aiEnabled}
              />
            </>
          ) : (
            <div id="no-aoi">
              <h2>{translate("general.no_aoi")}</h2>
            </div>
          )}
          <div ref={ref} style={{ height: "100vh", width: "100%" }} />{" "}
          {activeLayer !== "" && activeLayer !== "rgb" && !increaseContrast ? (
            <ColourScheme
              currentLayer={activeLayer}
              settings={settings}
              variance={variance}
            />
          ) : null}
          <Offcanvas
            placement="end"
            show={showControlPanel}
            onHide={handleClose}
            intro-tour="reactour__menu"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id="offcanvasNavbarLabel">
                <Translate value="menu.menu" />
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div>
                {availableAois.length === 0 && (
                  <div className="mb-4 bg-warning p-2">
                    {translate("menu.no_aoi")}
                  </div>
                )}
                <p>
                  <Translate value={"menu.choose_location"} />
                </p>
                <select
                  id="aoi-select"
                  value={currentAoi}
                  disabled={availableAois.length === 0}
                  className="form-control"
                  onChange={handleAoiChange}
                >
                  {addDropdownItems()}
                </select>
              </div>

              <div class="menu-buttons">
                <Dropdown>
                  <Dropdown.Toggle>
                    <ChangeLanguage locale={lang} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => changeLanguage("de")}>
                      <ChangeLanguage locale={"de"} />
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => changeLanguage("en")}>
                      <ChangeLanguage locale={"en"} />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <button
                  type="button"
                  class="btn-lg btn-light"
                  onClick={startTour}
                >
                  <Translate value="menu.restart_tour" />
                </button>
                <button type="button" class="btn-lg btn-light" onClick={logout}>
                  <Translate value="menu.logout" />
                </button>
              </div>
            </Offcanvas.Body>
          </Offcanvas>
          <Tour steps={getSteps()} isOpen={showTour} onRequestClose={endTour} />
          <div id="ctl-button">
            <button
              type="button"
              intro-tour="reactour__menu"
              class="gm-control-active gm-fullscreen-control"
              onClick={openControlPanel}
              id="gear-button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                enable-background="new 0 0 24 24"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#000000"
                id="gear-icon"
              >
                <g>
                  <path d="M0,0h24v24H0V0z" fill="none" />
                  <path d="M19.14,12.94c0.04-0.3,0.06-0.61,0.06-0.94c0-0.32-0.02-0.64-0.07-0.94l2.03-1.58c0.18-0.14,0.23-0.41,0.12-0.61 l-1.92-3.32c-0.12-0.22-0.37-0.29-0.59-0.22l-2.39,0.96c-0.5-0.38-1.03-0.7-1.62-0.94L14.4,2.81c-0.04-0.24-0.24-0.41-0.48-0.41 h-3.84c-0.24,0-0.43,0.17-0.47,0.41L9.25,5.35C8.66,5.59,8.12,5.92,7.63,6.29L5.24,5.33c-0.22-0.08-0.47,0-0.59,0.22L2.74,8.87 C2.62,9.08,2.66,9.34,2.86,9.48l2.03,1.58C4.84,11.36,4.8,11.69,4.8,12s0.02,0.64,0.07,0.94l-2.03,1.58 c-0.18,0.14-0.23,0.41-0.12,0.61l1.92,3.32c0.12,0.22,0.37,0.29,0.59,0.22l2.39-0.96c0.5,0.38,1.03,0.7,1.62,0.94l0.36,2.54 c0.05,0.24,0.24,0.41,0.48,0.41h3.84c0.24,0,0.44-0.17,0.47-0.41l0.36-2.54c0.59-0.24,1.13-0.56,1.62-0.94l2.39,0.96 c0.22,0.08,0.47,0,0.59-0.22l1.92-3.32c0.12-0.22,0.07-0.47-0.12-0.61L19.14,12.94z M12,15.6c-1.98,0-3.6-1.62-3.6-3.6 s1.62-3.6,3.6-3.6s3.6,1.62,3.6,3.6S13.98,15.6,12,15.6z" />
                </g>
              </svg>
            </button>
          </div>
        </>
      )}
    />
  );
}
