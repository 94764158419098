import { Link } from "react-router-dom";
import "./Login.scss";
import logo from "../../assets/images/greenway-logo.png";
import { getTokenApi, getClientSecretApi } from "../../services/api/authApi";
import { login } from "../../services/auth/authProvider";
import Dropdown from "react-bootstrap/Dropdown";
import { Translate, I18n, setLocale } from "react-i18nify";
import ChangeLanguage from "../../components/atoms/ChangeLanguage";
import { Component } from "react";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      locale: "en",
    };
    this.onInputchange = this.onInputchange.bind(this);
    this.setError = this.setError.bind(this);
    let lang = navigator.language || navigator.userLanguage;
    if (lang.toLowerCase().startsWith('de')) {
      setLocale('de');
      this.state = {
        error: "",
        locale: "de",
      };
    }
    else {
      setLocale('en')
      this.state = {
        error: "",
        locale: "en",
      };
    }
    
  }
  

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  doLogin = async (e) => {
    e.preventDefault();
    let secret = await login(this.state.email, this.state.password);
    if (!secret) {
      this.setError(<Translate value="login.wrong_password" />);
    } else {
      window.location.reload();
    }
  };

  setError = (error) => {
    this.setState({
      error: error,
    });
  };

  changeLanguage = (locale) => {
    this.setState({ locale: locale });
    setLocale(locale);
  };

  render() {
    return (
      <>
        <I18n
          render={() => (
            <section className="d-flex justify-content-center align-items-center flex-column login-section">
              <div class="container">
                <div class="greenway-logo">
                  <img src={logo} />
                </div>
                <div className="card login-card">
                  <div className="card-body">
                    {this.state.error != "" && (
                      <div className="mb-4 bg-warning p-2">
                        {this.state.error}
                      </div>
                    )}
                    <form id="login-form">
                      <div className="form-group">
                        <label>
                          <Translate value="login.email" />
                        </label>
                        <input
                          name="email"
                          type="email"
                          className="form-control"
                          value={this.state.email}
                          onChange={this.onInputchange}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          <Translate value="login.password" />
                        </label>
                        <input
                          name="password"
                          type="password"
                          className="form-control"
                          value={this.state.password}
                          onChange={this.onInputchange}
                        />
                      </div>
                      <div className="d-flex align-items-center login-items">
                        <button
                          className="btn login-buttons mr-4"
                          onClick={this.doLogin.bind(this)}
                        >
                          {" "}
                          <Translate value="login.login" />{" "}
                        </button>
                        <Link to="/register" className="btn login-buttons">
                          <Translate value="login.register" />
                        </Link>
                      </div>
                      <div className="form-group forgot-password">
                        <Link to="/reset">
                          <Translate value="login.forgot_password" />
                        </Link>
                      </div>
                      <div>
                        <Dropdown>
                          <Dropdown.Toggle>
                            <ChangeLanguage locale={this.state.locale} />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => this.changeLanguage("de")}
                            >
                              <ChangeLanguage locale="de" />
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => this.changeLanguage("en")}
                            >
                              <ChangeLanguage locale="en" />
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          )}
        />
      </>
    );
  }
}
