import React from "react";
import "./ColourScheme.scss";

export default function ColourScheme(props) {
  let visParamIndex = "standard";
  let currentLayer = props.currentLayer.includes("_")
    ? props.currentLayer.split("_")[1]
    : props.currentLayer;
  if (
    props.variance &&
    "variance" in props.settings.layers[currentLayer].visParams
  ) {
    visParamIndex = "variance";
  } else
    for (let key of Object.keys(
      props.settings.layers[currentLayer].visParams
    )) {
      if (props.currentLayer.includes(key)) visParamIndex = key;
    }

  let visParams = props.settings.layers[currentLayer].visParams[visParamIndex];
  let palette = ("" + visParams.palette).split(",");
  palette.reverse();

  let calculateSteps = function () {
    let step = (visParams.max - visParams.min) / 10;
    let scale = [];

    for (let i = 0; i <= 10; i++) {
      if ((i % 2 === 0) | (i === 0)) {
        let v = Math.round((visParams.min + i * step) * 100);
        if (currentLayer !== "smi") v /= 100;
        scale.unshift(
          <>
            <div class="step">
              <p>{v} -</p>
            </div>
          </>
        );
      } else
        scale.unshift(
          <div class="step">
            <p>-</p>
          </div>
        );
    }

    return scale;
  };

  return (
    <>
      <div class="legend-container">
        <p id="legend-header">
          {currentLayer === "smi" ? "VWC in %" : currentLayer.toUpperCase()}
        </p>
        <div class="steps">{calculateSteps()}</div>
        <div
          style={{ background: "linear-gradient(" + palette.join(",") + ")" }}
          class="gradient"
        ></div>
      </div>
    </>
  );
}
