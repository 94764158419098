import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Dashboard.scss";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Dashboard from "../../../utils/Dashboard";
import EditProfile from "../user/edit-profil/EditProfil";
import Delete from "../../../utils/Delete";
import CreateUser from "../user/create-user/CreateUser";
import { getApi } from "../../../services/api/requestApi";
import LoadingIcon from "../../../components/atoms/LoadingIcon";
import { postApi } from "../../../services/api/requestApi";
import UserProfil from "../user/user-profil/UserProfil";
import { Form } from "react-bootstrap";

export default class UserDashboard extends Dashboard {
  constructor(props) {
    super(props, {
      users: [],
      currentPage: 1,
      contentPerPage: 6,
      content: [],
      showEditProfilModal: false,
      showDeleteModal: false,
      showCreateModal: false,
      currentElem: null,
      currentKey: null,
      reload: false,
      loading: true,
      error: false,
      errorMessage: "",
    });
    this.setShowModal = this.setShowModal.bind(this);
    this.reload = this.reload.bind(this);
  }

  componentDidMount = async () => {
    this.reload();
  };

  reload() {
    this.load("users", "/admin/users");
  }

  /**
   * This function is called when a button on an element is pressed. All
   * the necessary data is set accordingly
   *
   * @author Hagen Hoppenstedt
   * @param type {string} The name of the modal that should be shown
   * @param elem {Object} The element data
   */
  setCurrentElement(type, key, elem = null) {
    this.setShowModal(type);
    this.setState({ currentElem: elem });
    this.setState({ currentKey: key });
  }

  /**
   * Sets the enabled parameter in the database, if it is set
   * to false then there are no more calls allowed for the user
   *
   * @author Hagen Hoppenstedt
   * @param id {String} Uuid of the user
   * @param status {Boolean} New status of enabled
   */
  setStatus = async (id, status) => {
    let url = "/admin/user/update";
    let body = {
      id: id,
      enabled: status,
    };
    let response = await postApi(url, body);
    if (response !== false) {
      this.reload();
    }
  };

  /**
   * Renders the content of the table
   *
   * @author Hagen Hoppenstedt
   */
  renderUserData() {
    if (this.state.loading) {
      return LoadingIcon();
    } else {
      let userData = [];
      const { SearchBar } = Search;
      const columns = [
        {
          dataField: "index",
          text: "#",
          sort: true,
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "username",
          text: "Username",
          sort: true,
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "email",
          text: "E-Mail",
          sort: true,
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "roles",
          text: "Roles",
          sort: true,
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
        },
        {
          dataField: "enabled",
          text: "Active",
          sort: true,
          headerStyle: { textAlign: "center" },
          style: { textAlign: "center" },
        },
        {
          dataField: "createdAt",
          text: "Created At",
          sort: true,
          sortFunc: (a, b, order) => {
            if (a == "N/A" && b == "N/A") return 0;
            else if (a == "N/A") {
              if (order == "asc") {
                return -1;
              } else return 1;
            } else if (b == "N/A") {
              {
                if (order == "asc") {
                  return 1;
                } else return -1;
              }
            }

            let tempA = a.split(" ");
            let dateA = tempA[0];
            dateA = dateA.split("-");
            dateA =
              "20" +
              dateA[2] +
              "-" +
              dateA[1] +
              "-" +
              dateA[0] +
              "T" +
              tempA[1];

            let tempB = b.split(" ");
            let dateB = tempB[0];
            dateB = dateB.split("-");
            dateB =
              "20" +
              dateB[2] +
              "-" +
              dateB[1] +
              "-" +
              dateB[0] +
              "T" +
              tempB[1];
            if (order === "asc") {
              return Date.parse(dateA) - Date.parse(dateB);
            } else if (order === "desc") {
              return Date.parse(dateB) - Date.parse(dateA);
            }
          },
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "lastLogin",
          text: "Last Login",
          sort: true,
          sortFunc: (a, b, order) => {
            if (a == "N/A" && b == "N/A") return 0;
            else if (a == "N/A") {
              if (order == "asc") {
                return -1;
              } else return 1;
            } else if (b == "N/A") {
              {
                if (order == "asc") {
                  return 1;
                } else return -1;
              }
            }

            let tempA = a.split(" ");
            let dateA = tempA[0];
            dateA = dateA.split("-");
            dateA =
              "20" +
              dateA[2] +
              "-" +
              dateA[1] +
              "-" +
              dateA[0] +
              "T" +
              tempA[1];

            let tempB = b.split(" ");
            let dateB = tempB[0];
            dateB = dateB.split("-");
            dateB =
              "20" +
              dateB[2] +
              "-" +
              dateB[1] +
              "-" +
              dateB[0] +
              "T" +
              tempB[1];
            if (order === "asc") {
              return Date.parse(dateA) - Date.parse(dateB);
            } else if (order === "desc") {
              return Date.parse(dateB) - Date.parse(dateA);
            }
          },
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "notifications_enabled",
          text: "Notifications enabled",
          sort: true,
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "edit",
          text: "",
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "delete",
          text: "",
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
      ];
      this.state.users.map((elem, index) => {
        let currentElem = Object.values(elem)[0];
        let currentKey = Object.keys(elem)[0];

        userData.push({
          index: index + 1,
          username: currentElem.username,
          email: currentElem.email,
          roles: currentElem.roles[0],
          enabled: (
            <button
              class="btn btn-light btn-sm"
              onClick={() => this.setStatus(currentKey, !currentElem.enabled)}
            >
              {currentElem.enabled ? "Active" : "Inactive"}
            </button>
          ),
          lastLogin:
            currentElem.lastLogin != null ? currentElem.lastLogin : "N/A",
          createdAt: currentElem.createdAt,
          notifications_enabled: (
            <>
              <Form.Check
                type={"checkbox"}
                checked={currentElem.notifications_enabled}
                disabled
              />
            </>
          ),
          edit: (
            <Link to={`/admin/user-profil/${currentKey}`}>
              <button class="btn btn-dark btn-sm">Edit</button>
            </Link>
          ),
          delete: (
            <button
              class="btn btn-danger btn-sm"
              onClick={() =>
                this.setCurrentElement("Delete", currentKey, currentElem)
              }
            >
              Delete
            </button>
          ),
        });
      });
      return (
        <>
          <ToolkitProvider
            keyField="id"
            data={userData}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <hr />
                <BootstrapTable
                  {...props.baseProps}
                  pagination={paginationFactory({ sizePerPage: 10 })}
                />
              </div>
            )}
          </ToolkitProvider>
          <div>
            <button
              class="btn btn-light create"
              onClick={() => this.setShowModal("Create")}
            >
              New User
            </button>
          </div>
        </>
      );
    }
  }

  render() {
    let table = [];
    table.push(this.renderUserData());
    table.push(
      this.state.showEditProfilModal && (
        <UserProfil
          reload={this.reload}
          source="dashboard"
          id={this.state.currentKey}
          elem={this.state.currentElem}
          setShowModal={this.setShowModal}
          showModal={this.state.showEditProfilModal}
        />
      )
    );
    table.push(
      this.state.showDeleteModal && (
        <Delete
          reload={this.reload}
          source="dashboard"
          type="User"
          id={this.state.currentKey}
          name={this.state.currentElem.username}
          setShowModal={this.setShowModal}
          showModal={this.state.showDeleteModal}
        />
      )
    );
    table.push(
      this.state.showCreateModal && (
        <CreateUser
          reload={this.reload}
          source="dashboard"
          type="User"
          setShowModal={this.setShowModal}
          showModal={this.state.showCreateModal}
        />
      )
    );
    return super.render(table);
  }
}
