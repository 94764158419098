import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Dashboard.scss";
import Table from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min";
import Dashboard from "../../../utils/Dashboard";
import Delete from "../../../utils/Delete";
import ICScript from "../icscript/ICScript";
import ICAIScript from "../icscript/ICAIScript";
import Logs from "../icscript/Logs";
import Execute from "../icscript/Execute";
import LoadingIcon from "../../../components/atoms/LoadingIcon";
import { Form } from "react-bootstrap";
import Select from "react-select";

export default class ICScriptDashboard extends Dashboard {
  constructor(props) {
    super(props, {
      currentElem: null,
      showEditICScriptModal: false,
      showDeleteModal: false,
      showICScriptModal: false,
      showICAIScriptModal: false,
      showEditICAIScriptModal: false,
      showExecuteModal: false,
      currentPage: 1,
      contentPerPage: 6,
      content: [],
      loading: true,
      error: false,
      errorMessage: "",
    });
    this.setShowModal = this.setShowModal.bind(this);
    this.reload = this.reload.bind(this);
  }

  componentDidMount = async () => {
    await this.reload();
  };

  reload = async () => {
    await this.load("icscripts", "/admin/icscripts/get");
    await this.load("aois", "/admin/aois");
  };

  /**
   * This function is called when a button on an element is pressed. All
   * the necessary data is set accordingly
   *
   * @author Hagen Hoppenstedt
   * @param type {string} The name of the modal that should be shown
   * @param elem {Object} The element data
   */
  setCurrentElement(selected, elem) {
    this.setShowModal(selected.value);
    this.setState({ currentElem: elem });
  }

  updateSettings(settings) {
    this.setState({ settings: settings });
  }

  /**
   * Renders the content of the table
   *
   * @author Hagen Hoppenstedt
   */
  renderScriptData() {
    if (this.state.loading) {
      return LoadingIcon();
    } else {
      let scriptData = [];
      const { SearchBar } = Search;
      const columns = [
        {
          dataField: "index",
          text: "#",
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "name",
          text: "Name",
          sort: true,
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "status",
          text: "Status",
          sort: true,
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "ai",
          text: "AI",
          sort: true,
          style: { textAlign: "center" },
          headerStyle: { textAlign: "center" },
        },
        {
          dataField: "options",
          text: "",
          style: { textAlign: "center" },
        },
      ];
      this.state.icscripts.map((elem, index) => {
        let currentElem = Object.values(elem)[0];
        currentElem.id = Object.keys(elem)[0];
        scriptData.push({
          index: index + 1,
          name: currentElem.name,
          status: currentElem.status,
          ai: (
            <>
              <Form.Check type={"checkbox"} checked={currentElem.ai} />
            </>
          ),
          options: (
            <div>
              <Select
                onChange={(selected) =>
                  this.setCurrentElement(selected, currentElem)
                }
                value={{ label: "Options", value: "Options" }}
                options={[
                  {
                    label: "Execute manually",
                    value: "Execute",
                  },
                  { label: "Show history", value: "Logs" },
                  {
                    label: "Edit",
                    value: currentElem.ai ? "EditICAIScript" : "EditICScript",
                  },
                  {
                    label: "Delete",
                    value: "Delete",
                    isDisabled: !currentElem.deleteable,
                  },
                ]}
                isSearchable={false}
              />
            </div>
          ),
        });
      });
      return (
        <>
          <ToolkitProvider
            keyField="id"
            data={scriptData}
            columns={columns}
            search
          >
            {(props) => (
              <div>
                <SearchBar {...props.searchProps} />
                <hr />
                <Table
                  {...props.baseProps}
                  pagination={paginationFactory({ sizePerPage: 10 })}
                />
              </div>
            )}
          </ToolkitProvider>
          <div id="create-ic-script">
            <Select
              onChange={(selected) => this.setShowModal(selected.value)}
              value={{ label: "Create", value: "Create" }}
              options={[
                {
                  label: "IC Script",
                  value: "ICScript",
                },
                {
                  label: "IC AI Script",
                  value: "ICAIScript",
                },
              ]}
              isSearchable={false}
            />
          </div>
        </>
      );
    }
  }

  render() {
    let table = [];
    table.push(this.renderScriptData());
    table.push(
      this.state.showExecuteModal && (
        <Execute
          currentElem={this.state.currentElem}
          load={this.load}
          reload={this.reload}
          setShowModal={this.setShowModal}
          showModal={this.state.showExecuteModal}
        />
      )
    );
    table.push(
      this.state.showLogsModal && (
        <Logs
          currentElem={this.state.currentElem}
          load={this.load}
          reload={this.reload}
          setShowModal={this.setShowModal}
          showModal={this.state.showLogsModal}
        />
      )
    );
    table.push(
      this.state.showEditICScriptModal && (
        <ICScript
          currentElem={this.state.currentElem}
          edit={true}
          reload={this.reload}
          setShowModal={this.setShowModal}
          showModal={this.state.showEditICScriptModal}
        />
      )
    );
    table.push(
      this.state.showEditICAIScriptModal && (
        <ICAIScript
          availableAois={this.state.aois}
          currentElem={this.state.currentElem}
          edit={true}
          reload={this.reload}
          setShowModal={this.setShowModal}
          showModal={this.state.showEditICAIScriptModal}
        />
      )
    );
    table.push(
      this.state.showDeleteModal && (
        <Delete
          reload={this.reload}
          source="dashboard"
          type={this.state.currentElem.ai ? "ICAIScript" : "ICScript"}
          id={this.state.currentElem.id}
          name={this.state.currentElem.alias}
          setShowModal={this.setShowModal}
          showModal={this.state.showDeleteModal}
        />
      )
    );
    table.push(
      this.state.showICScriptModal && (
        <ICScript
          currentElem={this.state.currentElem}
          edit={false}
          reload={this.reload}
          setShowModal={this.setShowModal}
          showModal={this.state.showICScriptModal}
        />
      )
    );
    table.push(
      this.state.showICAIScriptModal && (
        <ICAIScript
          availableAois={this.state.aois}
          currentElem={this.state.currentElem}
          edit={false}
          reload={this.reload}
          setShowModal={this.setShowModal}
          showModal={this.state.showICAIScriptModal}
        />
      )
    );
    return super.render(table);
  }
}
