import "./App.scss";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "rsuite/dist/rsuite.min.css";
import logo from "./logo.svg";
import Login from "./pages/login/Login";
import Register from "./pages/register/Register";
import Reset from "./pages/login/PasswordReset";
import Recovery from "./pages/login/PasswordRecovery";
import UserProfil from "./pages/admin/user/user-profil/UserProfil";
import Scripts from "./pages/admin/dashboard/ScriptDashboard";
import Users from "./pages/admin/dashboard/UserDashboard";
import Aois from "./pages/admin/dashboard/AoiDashboard";
import ICScripts from "./pages/admin/dashboard/ICScriptDashboard";
import UserView from "./pages/user/UserView";
import TrafficLight from "./components/molecules/widgets/Traffic-lights";
import { isLoggedIn, logout } from "./services/auth/authProvider";
import React, { Component } from "react";
import { setTranslations, setLocale } from "react-i18nify";
import Translations from "./assets/translations/translations.json";

class App extends Component {
  state = {
    loggedIn: false,
    admin: false,
    loaded: false,
  };

  componentDidMount = async () => {
    setTranslations(Translations);
    setLocale("de");

    let loggedIn = await isLoggedIn();
    if (loggedIn.success != false) {
      let role = loggedIn.data.roles[0];
      this.setState({ loggedIn: true });
      if (role == "ROLE_ADMIN") {
        this.setState({ admin: true });
      }
    } else {
      logout();
      this.setState({ error: loggedIn.data });
    }
    this.setState({ loaded: true });
  };

  render() {
    let routes = [
      <Route path="widgets/TrafficLight/:token" element={<TrafficLight />} />,
    ];
    if (this.state.loaded) {
      if (!this.state.loggedIn) {
        routes.push(<Route path="register" exact element={<Register />} />);
        routes.push(<Route path="login" exact element={<Login />} />);
        routes.push(<Route path="reset" exact element={<Reset />} />);
        routes.push(<Route path="recovery/:id" element={<Recovery />} />);
        routes.push(<Route path="*" element={<Navigate to="/login" />} />);
      } else if (this.state.admin) {
        routes.push(<Route path="gee" element={<UserView />} />);
        routes.push(<Route path="admin/users" element={<Users />} />);
        routes.push(
          <Route
            path="admin/user-profil/:id"
            element={<UserProfil />}
            forceRefresh
          />
        );
        routes.push(<Route path="admin/scripts" element={<Scripts />} />);
        routes.push(<Route path="admin/aois" element={<Aois />} />);
        routes.push(<Route path="admin/icscripts" element={<ICScripts />} />);
        routes.push(
          <Route path="*" element={<Navigate to="/admin/users" />} />
        );
      } else {
        routes.push(<Route path="gee" element={<UserView />} />);
        routes.push(<Route path="*" element={<Navigate to="/gee" />} />);
      }
    }
    return (
      <>
        <Router>
          <Routes>{routes}</Routes>
        </Router>
      </>
    );
  }
}

export default App;
