import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BASE_URL, GEE_URL } from "../../../env";
import ColourScheme from "../../atoms/ColourScheme";
import loadingIcon from "../../atoms/LoadingIcon";

export default function TrafficLight(props) {
  const params = useParams();
  const [settings, setSettings] = useState({});
  const [colors, setColors] = useState({});

  const getColor = function (value, minimum, maximum) {
    let grey = "rgba(224, 224, 235, 1)";
    if (value < minimum) {
      return {
        green: grey,
        orange: grey,
        red: "rgba(233, 75, 7, 1)",
        current: "red",
      };
    } else if (value < maximum) {
      return {
        green: grey,
        orange: "rgba(255, 180, 66, 1)",
        red: grey,
        current: "orange",
      };
    } else {
      return {
        green: "rgba(73, 222, 23, 1)",
        orange: grey,
        red: grey,
        current: "green",
      };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      let geometrics = {};
      let settings = {};
      let selectedGeos = [];
      let chartdata;
      await fetch(BASE_URL + "/api/user/widget", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: params.token,
        },
        body: JSON.stringify({}),
      })
        .then((response) => response.json())
        .then((data) => {
          geometrics = JSON.parse(data.polygons);
          settings = JSON.parse(data.settings);
          selectedGeos = [Object.keys(geometrics)[0]];
          setSettings(settings);
        })
        .catch((e) => {
          console.log(e);
        });

      await fetch(GEE_URL + "/charts", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          dates: { startDate: null, endDate: null },
          charts: settings.charts,
          selectedGeos,
          geometrics,
          settings,
          subpart: Object.keys(geometrics[selectedGeos[0]].Subparts)[0],
          range: false,
          image_collection: "image_collection",
        }),
      })
        .then((response) => response.text())
        .then((data) => {
          const formattedData = JSON.parse(data);

          
        })
        .catch((e) => {
          console.log(e);
        });
    };
    fetchData();
  }, []);

  if (Object.keys(colors).length > 0) {
    let lights = [];

    for (let layer of Object.keys(colors)) {
      lights.push(
        <>
          <div class="d-inline-flex align-items-center">
            <div style={{ width: "7vh" }}>{layer.toUpperCase()}</div>
            <div class="d-inline-flex traffic-lights">
              <div
                class="circle"
                style={{
                  backgroundColor: colors[layer].green,
                }}
              />
              <div
                class="circle"
                style={{
                  backgroundColor: colors[layer].orange,
                }}
              />
              <div
                class="circle"
                style={{
                  backgroundColor: colors[layer].red,
                }}
              />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div class="d-flex flex-column">{lights}</div>
      </>
    );
  }
}
