import React, { Component } from "react";
import { Form, Row, Col, Alert } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { postApi } from "../../../services/api/requestApi";
import Select from "react-select";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import CreatableSelect from "react-select/creatable";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import moment from "moment-timezone";
import { PROJECT_IDS } from "../../../env";
import { getApi } from "../../../services/api/requestApi";
import LoadingIcon from "../../../components/atoms/LoadingIcon";

export default class ICScript extends Component {
  static defaultAoiSettings = {
    IC_name: "",
    execution_times: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
      timezone: "UTC",
    },
    minBBox: "",
    Corners: "",
    ROI_geometry: "",
    project_id: PROJECT_IDS[0],
  };

  constructor(props) {
    super(props);
    let aoiSettings = {
      default: ICScript.defaultAoiSettings,
    };
    let downloadSettings = {
      cloud_cover_filter: "99",
      clear_confidence_filter: "0",
      visible_confidence_filter: "0",
      item_type: "PSScene",
    };
    let ppsSettings = {
      local_mean_clear_confidence_percent: 80,
      local_clear_percent: 0.99,
    };

    this.state = {
      edit: props.edit,
      showModal: props.showModal,
      setShowModal: props.setShowModal,
      reload: props.reload,
      loading: this.props.edit,
      error: "",
      selectedAoi: { label: "default", value: "default" },
      aoiSettings: aoiSettings,
      aoiSettingsJSON: JSON.stringify(aoiSettings, null, 2),
      aoiJSONError: "",
      downloadSettings: downloadSettings,
      downloadSettingsJSON: JSON.stringify(downloadSettings, null, 2),
      downloadJSONError: "",
      ppsSettings: ppsSettings,
      ppsSettingsJSON: JSON.stringify(ppsSettings, null, 2),
      ppsJSONError: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount = async () => {
    if (this.props.edit) {
      let response = await postApi("/admin/icscripts/get", {
        id: this.props.currentElem.id,
        type: "ICScript",
      });
      if (response.success) {
        let aoiSettings = JSON.parse(response.data.aoi_settings);
        let downloadSettings = JSON.parse(response.data.download_settings);
        let ppsSettings = JSON.parse(response.data.pps_settings);
        this.setState({
          aoiSettings,
          aoiSettingsJSON: JSON.stringify(aoiSettings, null, 2),
          downloadSettings,
          downloadSettingsJSON: JSON.stringify(downloadSettings, null, 2),
          ppsSettings,
          ppsSettingsJSON: JSON.stringify(ppsSettings, null, 2),
          selectedAoi: {
            label: Object.keys(aoiSettings)[0],
            value: Object.keys(aoiSettings)[0],
          },
          loading: false,
        });
      }
    }
  };

  handleClose = () => {
    this.state.setShowModal(
      this.state.edit ? "EditICScript" : "ICScript",
      false
    );
  };

  handleSubmit = async () => {
    let edit = this.props.edit;
    let url = edit ? "/admin/icscripts/update" : "/admin/icscripts/create";
    let body = {
      id: edit ? this.props.currentElem.id : "",
      type: "IC Script",
      download_settings: JSON.stringify(this.state.downloadSettings),
      pps_settings: JSON.stringify(this.state.ppsSettings),
      aoi_settings: JSON.stringify(this.state.aoiSettings),
    };
    let response = await postApi(url, body);
    if (response.success) {
      this.handleClose();
      this.state.reload();
    } else {
      this.setState({
        error: "Upload failed! Make sure your input is valid and try again.",
      });
    }
  };

  handleAddAoi = (newAoi) => {
    let aoiSettings = this.state.aoiSettings;
    if ("default" in this.state.aoiSettings) {
      aoiSettings[newAoi] = this.state.aoiSettings.default;
      delete aoiSettings.default;
    } else {
      aoiSettings[newAoi] = ICScript.defaultAoiSettings;
    }

    this.setState({
      selectedAoi: { label: newAoi, value: newAoi },
      aoiSettings: aoiSettings,
      aoiSettingsJSON: JSON.stringify(aoiSettings, null, 2),
    });
  };

  handleChange(e, type, fieldName = undefined) {
    switch (type) {
      case "aoi":
        if (fieldName === "json") {
          try {
            let aoiJSON = JSON.parse(e.target.value);
            let aoiSettings = this.state.aoiSettings;
            let jsonAois = Object.keys(aoiJSON);
            let settingsAois = Object.keys(aoiSettings);
            let valuesSet = false;
            for (let aoi of jsonAois) {
              if (aoi in aoiSettings || !this.props.edit) {
                if (!(aoi in aoiSettings)) {
                  aoiSettings[aoi] = ICScript.defaultAoiSettings;
                }
                for (let requiredField of Object.keys(aoiSettings[aoi])) {
                  if (requiredField in aoiJSON[aoi]) {
                    let value = aoiJSON[aoi][requiredField];
                    if (
                      typeof value === "object" &&
                      requiredField !== "execution_times"
                    ) {
                      value = JSON.stringify(value);
                    }
                    aoiSettings[aoi][requiredField] = value;
                  }
                }
                if ("BBoxes" in aoiJSON[aoi]) {
                  aoiSettings[aoi].minBBox = JSON.stringify(
                    aoiJSON[aoi].BBoxes.minBBox,
                    null,
                    2
                  );
                  aoiSettings[aoi].Corners = JSON.stringify(
                    aoiJSON[aoi].BBoxes.Corners,
                    null,
                    2
                  );
                }

                for (let deleteKey of settingsAois.filter(
                  (aoi) => !jsonAois.includes(aoi)
                )) {
                  delete aoiSettings[deleteKey];
                }

                valuesSet = true;
              }
            }
            if (valuesSet)
              this.setState({
                selectedAoi: {
                  label: Object.keys(aoiSettings)[0],
                  value: Object.keys(aoiSettings)[0],
                },
                aoiSettings: aoiSettings,
                aoiSettingsJSON: JSON.stringify(aoiSettings, null, 2),
                aoiJSONError: "",
              });
          } catch (error) {
            this.setState({
              aoiJSONError: error.message,
              aoiSettingsJSON: e.target.value,
            });
          }
        } else {
          let aoiSettings = this.state.aoiSettings;
          aoiSettings[this.state.selectedAoi.value][fieldName] = e.target.value;

          this.setState({
            aoiSettings: aoiSettings,
            aoiSettingsJSON: JSON.stringify(aoiSettings, null, 2),
          });
        }

        break;
      case "download":
        if (fieldName === "json") {
          try {
            let setting = JSON.parse(e.target.value);
            for (let deleteKey of Object.keys(setting).filter(
              (setting) =>
                !Object.keys(this.state.downloadSettings).includes(setting)
            )) {
              delete setting[deleteKey];
            }
            this.setState({
              downloadSettingsJSON: e.target.value,
              downloadSettings: setting,
            });
          } catch (error) {
            this.setState({
              downloadSettingsJSON: e.target.value,
              downloadJSONError: error.message,
            });
          }
        } else {
          let downloadSettings = this.state.downloadSettings;
          downloadSettings[fieldName] = e.target.value;

          this.setState({
            downloadSettings: downloadSettings,
            downloadSettingsJSON: JSON.stringify(downloadSettings, null, 2),
          });
        }
        break;
      case "pps":
        if (fieldName === "json") {
          try {
            let setting = JSON.parse(e.target.value);
            for (let deleteKey of Object.keys(setting).filter(
              (setting) =>
                !Object.keys(this.state.ppsSettings).includes(setting)
            )) {
              delete setting[deleteKey];
            }
            this.setState({
              ppsSettingsJSON: e.target.value,
              ppsSettings: setting,
            });
          } catch (error) {
            this.setState({
              ppsSettingsJSON: e.target.value,
              ppsJSONError: error.message,
            });
          }
        } else {
          let ppsSettings = this.state.ppsSettings;
          ppsSettings[fieldName] = e.target.value;

          this.setState({
            ppsSettings: ppsSettings,
            ppsSettingsJSON: JSON.stringify(ppsSettings, null, 2),
          });
        }
        break;
      case "executionTime":
        let aoiSettings = this.state.aoiSettings;
        let selectedAoi = this.state.selectedAoi.value;
        if (Array.isArray(e)) {
          aoiSettings[selectedAoi].execution_times[fieldName] = e.map(
            (time) => {
              return time.value;
            }
          );

          this.setState({ aoiSettings: aoiSettings });
        } else {
          let daysToIterate = [fieldName];
          if (fieldName === "every_day") {
            let { timezone, ...exec_times } =
              this.state.aoiSettings[selectedAoi].execution_times;
            daysToIterate = Object.keys(exec_times);
          }
          for (let day of daysToIterate) {
            if (
              !aoiSettings[selectedAoi].execution_times[day].includes(
                e.format("H:mm")
              )
            ) {
              aoiSettings[selectedAoi].execution_times[day] = [
                ...aoiSettings[selectedAoi].execution_times[day],
                e.format("H:mm"),
              ];
            }
          }
        }
        this.setState({
          aoiSettingsJSON: JSON.stringify(aoiSettings, null, 2),
          aoiSettings: aoiSettings,
        });

        break;
      default:
        console.log(e);
        break;
    }
  }

  renderDays() {
    let days = [];
    let { timezone, ...exec_times } =
      this.state.aoiSettings[this.state.selectedAoi.value].execution_times;
    for (let day of [...Object.keys(exec_times), "every_day"]) {
      let formattedDay = (day.charAt(0).toUpperCase() + day.slice(1)).replace(
        "_",
        " "
      );
      days.push(
        <Row className="mb-3">
          <Form.Group as={Col}>
            <Form.Label>{formattedDay}</Form.Label>
          </Form.Group>
          <Form.Group as={Col}>
            <TimePicker
              defaultValue={moment().minute(0).second(0)}
              showSecond={false}
              minuteStep={15}
              onChange={(e) => {
                this.handleChange(e, "executionTime", day);
              }}
            />
          </Form.Group>
          {!(day === "every_day") ? (
            <Form.Group as={Col}>
              <Select
                isMulti
                value={this.state.aoiSettings[
                  this.state.selectedAoi.value
                ].execution_times[day]
                  .sort()
                  .map((time) => {
                    return { label: time, value: time };
                  })}
                options={this.state.aoiSettings[
                  this.state.selectedAoi.value
                ].execution_times[day].map((time) => {
                  return {
                    label: time,
                    value: time,
                    isDisabled: true,
                  };
                })}
                onChange={(e) => this.handleChange(e, "executionTime", day)}
                isDisabled={
                  this.state.aoiSettings[this.state.selectedAoi.value]
                    .execution_times[day].size === 0
                }
              />
            </Form.Group>
          ) : (
            <Form.Group as={Col}>
              <p> </p>
            </Form.Group>
          )}
        </Row>
      );
    }

    return days;
  }

  renderAoiSelection() {
    return (
      <Row className="mb-3">
        <Form.Group as={Col} className="4">
          <CreatableSelect
            options={Object.keys(this.state.aoiSettings).map((key) => {
              if (key === "default")
                return {
                  value: "default",
                  label: "default",
                  isDisabled: true,
                };
              return {
                label: key,
                value: key,
              };
            })}
            value={this.state.selectedAoi}
            onCreateOption={this.handleAddAoi}
            onChange={(selectedAoi) => {
              this.setState({
                selectedAoi,
              });
            }}
            isDisabled={this.props.edit}
          />
          <Form.Text muted>
            Please enter the name for the aoi or select an existing one. You may
            add multiple aois.
          </Form.Text>
        </Form.Group>
      </Row>
    );
  }

  render() {
    let aoiData = this.state.aoiSettings[this.state.selectedAoi.value];
    return (
      <>
        <Modal
          size="lg"
          animation={false}
          show={this.state.showModal}
          onHide={this.handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.edit ? <>Edit script</> : <>Upload script</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.error !== "" && (
              <Alert variant="danger" transition={false}>
                {this.state.error}
              </Alert>
            )}
            {this.state.loading ? (
              LoadingIcon()
            ) : (
              <Tabs forceRenderTabPanel>
                <TabList>
                  <Tab>AoI</Tab>
                  <Tab>Download</Tab>
                  <Tab>PPS</Tab>
                  <Tab>Execution Times</Tab>
                </TabList>
                {/* Aoi settings */}
                <TabPanel>
                  <Tabs forceRenderTabPanel>
                    <TabList>
                      <Tab>Form</Tab>
                      <Tab>JSON</Tab>
                    </TabList>
                    {/* Form */}
                    <TabPanel>
                      <Form>
                        {this.renderAoiSelection()}
                        <Row className="mb-3">
                          <Form.Group as={Col} className="4">
                            <Form.Label>Project name</Form.Label>
                            <Form.Select
                              value={aoiData.project_id}
                              onChange={(e) =>
                                this.handleChange(e, "aoi", "project_id")
                              }
                            >
                              {PROJECT_IDS.map((projectId) => (
                                <option value={projectId}>{projectId}</option>
                              ))}
                            </Form.Select>
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="4">
                            <Form.Label>IC name</Form.Label>
                            <Form.Control
                              required
                              as="textarea"
                              rows={1}
                              value={aoiData.IC_name}
                              isValid={aoiData.IC_name !== ""}
                              onChange={(e) =>
                                this.handleChange(e, "aoi", "IC_name")
                              }
                            />
                          </Form.Group>
                        </Row>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="4">
                            <Form.Label>minBBox</Form.Label>
                            <Form.Control
                              required
                              as="textarea"
                              rows={4}
                              value={
                                typeof aoiData.minBBox === "object"
                                  ? JSON.stringify(aoiData.minBBox)
                                  : aoiData.minBBox
                              }
                              isValid={/^([\s\S]*\{[\s\S]*\}[\s\S]*)$/.test(
                                aoiData.minBBox
                              )}
                              isInvalid={
                                !/^([\s\S]*\{[\s\S]*\}[\s\S]*)$/.test(
                                  aoiData.Corners
                                ) && aoiData.minBBox !== ""
                              }
                              onChange={(e) =>
                                this.handleChange(e, "aoi", "minBBox")
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              This has to be a valid Object!
                            </Form.Control.Feedback>
                          </Form.Group>
                          <Form.Group as={Col} className="4">
                            <Form.Label>Corners</Form.Label>
                            <Form.Control
                              required
                              as="textarea"
                              rows={4}
                              value={aoiData.Corners}
                              isValid={/^([\s\S]*\{[\s\S]*\}[\s\S]*)$/.test(
                                aoiData.Corners
                              )}
                              isInvalid={
                                !/^([\s\S]*\{[\s\S]*\}[\s\S]*)$/.test(
                                  aoiData.Corners
                                ) && aoiData.Corners !== ""
                              }
                              onChange={(e) =>
                                this.handleChange(e, "aoi", "Corners")
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              This has to be a valid Object!
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                        {/* <Row className="mb-3">
                        <Form.Group as={Col} className="10">
                          <Form.Label>ROI_geometry</Form.Label>
                          <Form.Control
                            id="ROI_geometry"
                            required
                            as="textarea"
                            rows={4}
                            value={aoiData.ROI_geometry}
                            isValid={/(^ee\.Geometry.+)$/.test(
                              aoiData.ROI_geometry
                            )}
                            isInvalid={
                              !/(^ee\.Geometry.+)$/.test(
                                aoiData.ROI_geometry
                              ) && aoiData.ROI_geometry !== ""
                            }
                            onChange={(e) =>
                              this.handleChange(e, "aoi", "ROI_geometry")
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            This has to be of type 'ee.Geometry'!
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row> */}
                      </Form>
                    </TabPanel>
                    {/* JSON */}
                    <TabPanel>
                      <Form>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="10">
                            <Form.Control
                              required
                              as="textarea"
                              rows={20}
                              value={this.state.aoiSettingsJSON}
                              onChange={(e) =>
                                this.handleChange(e, "aoi", "json")
                              }
                            />
                            <Form.Text muted>
                              {this.state.aoiJSONError}
                            </Form.Text>
                          </Form.Group>
                        </Row>
                      </Form>
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                {/* Download settings */}
                <TabPanel>
                  <Tabs forceRenderTabPanel>
                    <TabList>
                      <Tab>Form</Tab>
                      <Tab>JSON</Tab>
                    </TabList>
                    {/* Form */}
                    <TabPanel>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="4">
                          <Form.Label>cloud_cover_filter</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            value={
                              this.state.downloadSettings.cloud_cover_filter
                            }
                            isValid={
                              this.state.downloadSettings.cloud_cover_filter !==
                              ""
                            }
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                "download",
                                "cloud_cover_filter"
                              )
                            }
                          />
                        </Form.Group>
                        <Form.Group as={Col} className="4">
                          <Form.Label>clear_confidence_filter</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            value={
                              this.state.downloadSettings
                                .clear_confidence_filter
                            }
                            isValid={
                              this.state.downloadSettings
                                .clear_confidence_filter !== ""
                            }
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                "download",
                                "clear_confidence_filter"
                              )
                            }
                          />
                        </Form.Group>
                      </Row>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="4">
                          <Form.Label>visible_confidence_filter</Form.Label>
                          <Form.Control
                            required
                            type="number"
                            value={
                              this.state.downloadSettings
                                .visible_confidence_filter
                            }
                            isValid={
                              this.state.downloadSettings
                                .visible_confidence_filter !== ""
                            }
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                "download",
                                "visible_confidence_filter"
                              )
                            }
                          />
                        </Form.Group>
                        <Form.Group as={Col} className="4">
                          <Form.Label>item_type</Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            rows={1}
                            value={this.state.downloadSettings.item_type}
                            isValid={
                              this.state.downloadSettings.item_type !== ""
                            }
                            onChange={(e) =>
                              this.handleChange(e, "download", "item_type")
                            }
                          />
                        </Form.Group>
                      </Row>
                    </TabPanel>
                    {/* JSON */}
                    <TabPanel>
                      <Form>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="10">
                            <Form.Control
                              required
                              as="textarea"
                              rows={20}
                              value={this.state.downloadSettingsJSON}
                              onChange={(e) =>
                                this.handleChange(e, "download", "json")
                              }
                            />
                            <Form.Text muted>
                              {this.state.downloadJSONError}
                            </Form.Text>
                          </Form.Group>
                        </Row>
                      </Form>
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                {/* PPS settings */}
                <TabPanel>
                  <Tabs forceRenderTabPanel>
                    <TabList>
                      <Tab>Form</Tab>
                      <Tab>JSON</Tab>
                    </TabList>
                    {/* Form */}
                    <TabPanel>
                      <Row className="mb-3">
                        <Form.Group as={Col} className="4">
                          <Form.Label>local_clear_percent</Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            rows={1}
                            value={this.state.ppsSettings.local_clear_percent}
                            isValid={
                              this.state.ppsSettings.local_clear_percent !== ""
                            }
                            onChange={(e) =>
                              this.handleChange(e, "pps", "local_clear_percent")
                            }
                          />
                        </Form.Group>
                        <Form.Group as={Col} className="4">
                          <Form.Label>
                            local_mean_clear_confidence_percent
                          </Form.Label>
                          <Form.Control
                            required
                            as="textarea"
                            rows={1}
                            value={
                              this.state.ppsSettings
                                .local_mean_clear_confidence_percent
                            }
                            isValid={
                              this.state.ppsSettings
                                .local_mean_clear_confidence_percent !== ""
                            }
                            onChange={(e) =>
                              this.handleChange(
                                e,
                                "pps",
                                "local_mean_clear_confidence_percent"
                              )
                            }
                          />
                        </Form.Group>
                      </Row>
                    </TabPanel>
                    {/* JSON */}
                    <TabPanel>
                      <Form>
                        <Row className="mb-3">
                          <Form.Group as={Col} className="10">
                            <Form.Control
                              required
                              as="textarea"
                              rows={20}
                              value={this.state.ppsSettingsJSON}
                              onChange={(e) =>
                                this.handleChange(e, "pps", "json")
                              }
                            />
                            <Form.Text muted>
                              {this.state.ppsJSONError}
                            </Form.Text>
                          </Form.Group>
                        </Row>
                      </Form>
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                {/* Execution times */}
                <TabPanel>
                  <Form>
                    {this.renderAoiSelection()}
                    <Row className="mb-3">
                      <Form.Group as={Col} className="10">
                        <Select
                          value={{
                            label:
                              this.state.aoiSettings[
                                this.state.selectedAoi.value
                              ].execution_times.timezone,
                            value:
                              this.state.aoiSettings[
                                this.state.selectedAoi.value
                              ].execution_times.timezone,
                          }}
                          options={moment.tz.names().map((timezone) => {
                            return { label: timezone, value: timezone };
                          })}
                          onChange={(timezone) => {
                            let aoiSettings = this.state.aoiSettings;
                            aoiSettings[
                              this.state.selectedAoi.value
                            ].execution_times.timezone = timezone.value;
                            this.setState({ aoiSettings: aoiSettings });
                          }}
                        />
                      </Form.Group>
                      <Form.Text muted>
                        The timezone can be changed here, default is UTC.
                      </Form.Text>
                    </Row>
                    {this.renderDays()}
                  </Form>
                </TabPanel>
              </Tabs>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div class="script-upload-footer">
              <button
                className="btn btn-dark mr-3"
                type="button"
                onClick={() => this.handleSubmit()}
              >
                Upload
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
