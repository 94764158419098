import React, { useState } from "react";
import { useParams, Link, withRouter } from "react-router-dom";
import "./UserProfil.scss";
import NavigationBar from "../../../../components/navigation-bar/NavigationBar";
import satelliteImg from "../../../../assets/images/satellite.jpg";
import loginImg from "../../../../assets/images/login.jpg";
import codeImg from "../../../../assets/images/code.jpg";
import EditProfil from "../edit-profil/EditProfil";
import DeleteProfil from "../../../../utils/Delete";
import ChangeAoi from "../change-aoi/ChangeAoi";
import ChangeMembership from "../change-membership/ChangeMembership";
import ChangePassword from "../change-password/ChangePassword";
import { Component } from "react";
import { getApi } from "../../../../services/api/requestApi";
import LoadingIcon from "../../../../components/atoms/LoadingIcon";
import Table from "react-bootstrap/Table";
import ShowSessions from "../show-sessions/ShowSessions";

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class UserProfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      address: "",
      phone: "",
      zip: "",
      city: "",
      membership: "",
      roles: [],
      locale: "",
      currentAoi: "",
      currentElem: null,
      availableAois: null,
      showEditProfilModal: false,
      showDeleteProfilModal: false,
      showChangeAoiModal: false,
      showChangeMembershipModal: false,
      showChangePasswordModal: false,
      showShowSessionsModal: false,
      loading: true,
      error: false,
      errorMessage: "",
    };
    this.setShowModal = this.setShowModal.bind(this);
  }

  componentDidMount = async () => {
    this.reload().then(() => {
      this.setState({ loading: false });
    });
  };

  reload = async () => {
    let response = await getApi("/admin/user/" + this.props.params.id);
    if (response.success) {
      this.setState({
        id: response.data.id,
        username: response.data.username,
        firstName: response.data.first_name,
        lastName: response.data.last_name,
        city: response.data.city,
        email: response.data.email,
        address: response.data.address,
        phone: response.data.phone,
        zip: response.data.zip,
        currentElem: response.data,
        membership: response.data.membership,
        roles: response.data.roles,
        currentAoi: response.data.aoi,
        availableAois: response.data.available_aois,
        allAois: response.data.all_aois,
        locale: response.data.locale,
      });
    } else {
      let message;
      try {
        message = ": " + (await response.data.json());
      } catch (e) {
        message = "";
      }
      this.setState({ error: true });
      this.setState({
        errorMessage: "Error " + response.data.status + message,
      });
    }
  };

  setShowModal(type, show = true) {
    let key = "show" + type + "Modal";
    this.setState({ [key]: show });
  }

  render() {
    return (
      <>
        <NavigationBar />
        {!this.state.error && (
          <section className="container mt-4">
            {this.state.loading && LoadingIcon()}
            {!this.state.loading && (
              <>
                <div className="profile-ändern-card">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="profile-ändern-img-container">
                        <img
                          src={satelliteImg}
                          className="profile-ändern-img"
                          alt=""
                        />
                        <div
                          className="img-title-container pl-4"
                          onClick={(e) => this.setShowModal("EditProfil")}
                        >
                          <p>Change profile</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <Table id="tbl-user-info" responsive="md">
                        <tbody>
                          <tr>
                            <td>Address</td>
                            <td>{this.state.address}</td>
                          </tr>
                          <tr>
                            <td>City</td>
                            <td>{this.state.city}</td>
                          </tr>
                          <tr>
                            <td>ZIP</td>
                            <td>{this.state.zip}</td>
                          </tr>
                          <tr>
                            <td>Telephone</td>
                            <td>{this.state.phone}</td>
                          </tr>
                          <tr>
                            <td>E-Mail</td>
                            <td>{this.state.email}</td>
                          </tr>
                          <tr>
                            <td>Preferred language</td>
                            <td>{this.state.locale}</td>
                          </tr>
                          <tr>
                            <td>Current Area of Interest</td>
                            <td>{this.state.currentAoi}</td>
                          </tr>
                          <tr>
                            <td>Available Area of Interest</td>
                            <td>{this.state.availableAois.join(", ")}</td>
                          </tr>
                          <tr>
                            <td>Roles</td>
                            <td>{this.state.roles.join(", ")}</td>
                          </tr>
                        </tbody>
                      </Table>
                      <div id="show-sessions-button">
                        <button
                          class="btn btn-light"
                          onClick={(e) => this.setShowModal("ShowSessions")}
                        >
                          Show sessions
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="gold-member-container mt-3">
                  <p>{this.state.membership}</p>
                </div>
                <div className="row mt-3">
                  <div className="col-md-4 mb-4">
                    <div className="aoi-ändern-img-container">
                      <img
                        src={satelliteImg}
                        className="aoi-ändern-img"
                        alt=""
                      />
                      <div
                        className="img-title-container pl-4"
                        onClick={(e) => this.setShowModal("ChangeAoi")}
                      >
                        <p>Change AoI</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="mitgliedschaft-ändern-img-container">
                      <img
                        src={loginImg}
                        className="mitgliedschaft-ändern-img"
                        alt=""
                      />
                      <div
                        className="img-title-container pl-4"
                        onClick={(e) => this.setShowModal("ChangeMembership")}
                      >
                        <p>Change Membership</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 mb-4">
                    <div className="mitgliedschaft-ändern-img-container">
                      <img
                        src={loginImg}
                        className="mitgliedschaft-ändern-img"
                        alt=""
                      />
                      <div
                        className="img-title-container pl-4"
                        onClick={(e) => this.setShowModal("ChangePassword")}
                      >
                        <p>Change Password</p>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </section>
        )}
        {this.state.error && (
          <div className="form-group">
            <p>{this.state.errorMessage}</p>
          </div>
        )}
        {this.state.showEditProfilModal && (
          <EditProfil
            reload={this.reload}
            source="profile"
            id={this.state.id}
            elem={this.state.currentElem}
            setShowModal={this.setShowModal}
            showModal={this.state.showEditProfilModal}
          />
        )}
        {this.state.showDeleteProfilModal && (
          <DeleteProfil
            reload={this.reload}
            source="profile"
            type="User"
            id={this.state.id}
            name={this.state.username}
            setShowModal={this.setShowModal}
            showModal={this.state.showDeleteProfilModal}
          />
        )}
        {this.state.showChangeAoiModal && (
          <ChangeAoi
            reload={this.reload}
            source="profile"
            id={this.state.id}
            currentAoi={this.state.currentAoi}
            availableAois={this.state.availableAois}
            allAois={this.state.allAois}
            setShowModal={this.setShowModal}
            showModal={this.state.showChangeAoiModal}
          />
        )}
        {this.state.showChangeMembershipModal && (
          <ChangeMembership
            reload={this.reload}
            source="profile"
            id={this.state.id}
            currentMembership={this.state.membership}
            setShowModal={this.setShowModal}
            showModal={this.state.showChangeMembershipModal}
          />
        )}
        {this.state.showChangePasswordModal && (
          <ChangePassword
            reload={this.reload}
            source="profile"
            id={this.state.id}
            setShowModal={this.setShowModal}
            showModal={this.state.showChangePasswordModal}
          />
        )}
        {this.state.showShowSessionsModal && (
          <ShowSessions
            reload={this.reload}
            source="profile"
            id={this.state.id}
            setShowModal={this.setShowModal}
            showModal={this.state.showShowSessionsModal}
          />
        )}
      </>
    );
  }
}

export default withParams(UserProfil);