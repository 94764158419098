import React from "react";
import "./ChangeAoi.scss";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Component } from "react";
import { postApi } from "../../../../services/api/requestApi";
import Select from "react-select";

export default class ChangeAoi extends Component {
  constructor(props) {
    super(props);
    let selectedAois = [];
    for (let aoi of this.props.availableAois) {
      selectedAois.push({ label: aoi, value: aoi });
    }
    this.state = {
      showModal: props.showModal,
      setShowModal: props.setShowModal,
      primaryAoi: "Select...",
      selectedAois: selectedAois,
      source: props.source,
      reload: props.reload,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateSelectedAois = this.updateSelectedAois.bind(this);
    this.updatePrimaryAoi = this.updatePrimaryAoi.bind(this);
  }

  handleClose = () => {
    this.state.setShowModal("ChangeAoi", false);
  };

  handleSubmit = async () => {
    if (
      this.state.selectedAois.length > 0 &&
      this.state.primaryAoi != "Select..."
    ) {
      let url = "/admin/user/update";
      let availableAois = [];
      this.state.selectedAois.map((aoi) => {
        availableAois.push(aoi.label);
      });
      let body = {
        id: this.props.id,
        aoi: this.state.primaryAoi,
        available_aois: availableAois,
      };
      let response = await postApi(url, body);
      if (response !== false) {
        this.handleClose();
        this.state.reload();
      }
    } else {
      alert("Please choose correct values");
    }
  };

  renderAois() {
    let options = [];
    this.props.allAois.map((aoi) => {
      options.push({ value: aoi.name, label: aoi.name, isDisabled: !aoi.valid });
    });
    return options;
  }

  renderSelectedAois() {
    let options = [];
    this.state.selectedAois.map((aoi) => {
      options.push(<option value={aoi}>{aoi}</option>);
    });
    return options;
  }

  updateSelectedAois(selections) {
    this.setState({
      selectedAois: selections,
      primaryAoi: "Select...",
    });
  }

  updatePrimaryAoi(selection) {
    this.setState({ primaryAoi: selection.value });
  }

  render() {
    return (
      <>
        <Modal
          animation={false}
          show={this.state.showModal}
          onHide={this.handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Change AoI</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="pb-4 mb-4">
              <div className="form-group pl-4 pr-4">
                <p>Choose available AoIs for the user</p>
                <Select
                  isMulti
                  defaultValue={this.state.selectedAois}
                  options={this.renderAois()}
                  onChange={this.updateSelectedAois}
                />
              </div>
              <div className="form-group pl-4 pr-4">
                <p>Set primary AoI</p>
                <Select
                  placeholder={this.state.primaryAoi}
                  value={this.state.primaryAoi}
                  options={this.state.selectedAois}
                  onChange={this.updatePrimaryAoi}
                />
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <button
                className="btn btn-success mr-3"
                onClick={this.handleSubmit}
                type="button"
              >
                Change
              </button>
              <button
                className="btn btn-danger"
                onClick={this.handleClose}
                type="button"
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
