import {BASE_URL} from '../../env.js';

export const getClientSecretApi = async () => {
    let data;
    try {
        data = await fetch(BASE_URL + '/oauth/createClient', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
    } catch (e) {}
    let response = await data.json();
    return {
        "success": data.status === 200,
        "data": response
    }
}

export const getTokenApi = async (user, pass, clientId, clientSecret) => {
    let data;
    let formData = 'client_id='+clientId+'&client_secret='+clientSecret+'&grant_type=password&username='+encodeURIComponent(user)+'&password='+encodeURIComponent(pass);
    try {
        data = await fetch(BASE_URL + '/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData,
        })
    } catch (e) {}
    let response = await data.json();
    return {
        "success": data.status === 200,
        "data": response
    };
}