import "./LoadingDots.scss";

export default function loadingDots () {
    return (
        <>
            <div class="loading-dots">
                <div class="bounce"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>
        </>
    )
}