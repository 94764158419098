import React from "react";
import "./ShowSessions.scss";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { Component } from "react";
import { postApi } from "../../../../services/api/requestApi";
import Table from "react-bootstrap/Table";

export default class ShowSessions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      showModal: props.showModal,
      setShowModal: props.setShowModal,
      response: [],
      source: props.source,
      reload: props.reload,
    };
  }

  componentDidMount = async () => {
    let body = {
      id: this.state.id,
    };
    let response = await postApi("/admin/user/sessions/get", body);
    this.setState({ response: response.data });
  };

  handleClose = () => {
    this.state.setShowModal("ShowSessions", false);
  };

  renderSessionContent = function () {
    let table = [];
    let response = this.state.response;
    for (let date in response) {
      let hours = [];

      for (let hour in response[date]) {
        let data = [];
        let totalTime = 0;
        for (let d of response[date][hour]) {
          totalTime += d.activityTime;
          data.push(
            <tr>
              <td>
                <b>{d.aoi + ":"}</b>
              </td>
              <td>
                {Math.floor(d.activityTime / 60) +
                  "m " +
                  (d.activityTime % 60) +
                  "s"}
              </td>
            </tr>
          );
        }
        data.push(
          <tr>
            <td>
              <b>Total:</b>
            </td>
            <td>
              {Math.floor(totalTime / 60) + "m " + (totalTime % 60) + "s"}
            </td>
          </tr>
        );
        hours.push(
          <>
            <tr>
              <td>{hour}</td>
              <td>{data}</td>
            </tr>
          </>
        );
      }
      table.push(
        <>
          <tr>
            <td>{date}</td>
            <td>{hours}</td>
          </tr>
        </>
      );
    }
    return table;
  };

  render() {
    return (
      <>
        <Modal
          animation={false}
          show={this.state.showModal}
          onHide={this.handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Session details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive="md" bordered hover>
              <thead>
                <th>Date</th>
                <th>Time</th>
              </thead>
              <tbody>{this.renderSessionContent()}</tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex">
              <button
                className="btn btn-light"
                onClick={this.handleClose}
                type="button"
              >
                OK
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
