import "./LoadingIcon.scss";

export default function loadingIcon(size = 8) {
  return (
    <>
      <div class="loader-container">
        <div class="loader" style={{ height: size + 'rem', width: size + 'rem' }} />
      </div>
    </>
  );
}
