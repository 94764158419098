import { Component } from "react";
import "./Login.scss";
import { postApi } from "../../services/api/requestApi";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import { Translate, setLocale } from "react-i18nify";

export default class PasswordRecovery extends Component {
  constructor() {
    super();
    this.state = {
      error: "",
    };
    this.onInputchange = this.onInputchange.bind(this);

    let lang = navigator.language || navigator.userLanguage;
    if (lang.toLowerCase().startsWith('de')) {
        setLocale('de')
    }
    else {
        setLocale('en')
    }
  }

  resetPassword = async (e) => {
    e.preventDefault();
    if (this.state.newPassword != this.state.repeatNewPassword) {
      this.setError(<Translate value="password_reset.pw_change_error_mismatch" />);
    } else {
      let url = "/oauth/changePassword";
      let body = {
        id: this.props.match.params.id,
        password: this.state.newPassword,
      };
      let response = await postApi(url, body);
      if (response !== false) {
        this.setState({
          success: true,
          header: <Translate value="password_reset.pw_change_successful_header" />,
          body: <Translate value="password_reset.pw_change_successful_body" />,
        });
      } else {
        this.setState({
          error:
          <Translate value="password_reset.pw_change_error_general" />,
        });
      }
    }
  };

  onInputchange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    });
  }

  render() {
    return (
      <>
        <section className="d-flex justify-content-center align-items-center flex-column login-section">
          <div>
            <div className="card login-card">
              <div className="card-header"><Translate value="password_reset.password_reset"/></div>
              <div className="card-body">
                {this.state.error != "" && (
                  <div className="mb-4 bg-warning p-2"> {this.state.error}</div>
                )}
                <form>
                  <div className="form-group">
                    <label><Translate value="password_reset.new_pw"/></label>
                    <input
                      name="newPassword"
                      type="password"
                      className="form-control"
                      value={this.state.newPassword}
                      onChange={this.onInputchange}
                    />
                  </div>
                  <div className="form-group">
                    <label><Translate value="password_reset.repeat_new_pw"/></label>
                    <input
                      name="repeatNewPassword"
                      type="password"
                      className="form-control"
                      value={this.state.repeatNewPassword}
                      onChange={this.onInputchange}
                    />
                  </div>
                  <div className="d-flex align-items-center login-items">
                    <Link to="/login" className="btn login-buttons">
                    <Translate value="general.login"/>
                    </Link>
                    <button
                      className="btn login-buttons mr-4"
                      onClick={this.resetPassword.bind(this)}
                    >
                      {" "}
                      <Translate value="password_reset.reset"/>{" "}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
        <Modal animation={false} show={this.state.success} size="lg" centered>
          <Modal.Header>{this.state.header}</Modal.Header>
          <Modal.Body>{this.state.body}</Modal.Body>
          <Modal.Footer>
            <Link to="/login" className="btn login-button">
            <Translate value="general.login"/>
            </Link>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
