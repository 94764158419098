
export const saveToken = async (login) => {
    let token = login.data.access_token;
    let type = login.data.token_type;
    let expiry;
    expiry = Date.now() + login.data.expires_in;
    localStorage.setItem('token', type+' '+token)
    localStorage.setItem('expiry', expiry);
}

//todo:logout on expiry
export const checkExpiry = async () => {
    let expiry = localStorage.getItem('expiry')
    if (expiry) {
        let valid = expiry - Date.now();
        if (valid > 0) {
            return true;
        }
        else {
            return false;
        }
    }else {
        return false;
    }
}

export const getToken = async () => {
    let expiry = await checkExpiry();
    if (expiry) {
        let token = localStorage.getItem('token')
        if (token) {
            return token;
        }else {
            return false;
        }
    }else {
        return false;
    }
    
}

