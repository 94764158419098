import React, { useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import Modal from "react-bootstrap/Modal";
import "./chart.scss";
import moment from "moment";
import localization from "moment/locale/de";
import annotationPlugin from "chartjs-plugin-annotation";
import { translate } from "react-i18nify";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

export default function Chart(props) {
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  let rangeSet =
    "max" in props.settings.layers[props.band.toLowerCase()] &&
    "min" in props.settings.layers[props.band.toLowerCase()];

  let data = {
    labels: props.xAxis,
    datasets: props.yAxis,
  };

  let options = {
    scales: {
      x: {
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          align: "start",
          callback: function (value, index) {
            moment.locale("de", localization);
            let date = moment(this.getLabelForValue(value));
            return index %
              Math.round(
                show ? data.labels.length / 8 : data.labels.length / 3.7
              ) ==
              0
              ? date.format("DD.MM.YY")
              : "";
          },
        },
      },
    },
  };

  if (!props.showAbs) {
    let ratio =
      "ratio" in props.settings.layers[props.band.toLowerCase()]
        ? props.settings.layers[props.band.toLowerCase()].ratio
        : 1.3;
    data.datasets = [
      {
        ...props.yAxis[0],
        data: props.yAxis[0].data.map((value) => {
          return (value / ratio) * 100;
        }),
      },
    ];

    let min = rangeSet
      ? props.settings.layers[props.band.toLowerCase()].min
      : 0.3;
    let max = rangeSet
      ? props.settings.layers[props.band.toLowerCase()].max
      : 0.85;

    min = (min / ratio) * 100;
    max = (max / ratio) * 100;

    options.plugins = {
      annotation: {
        annotations: {
          bad: {
            drawTime: "beforeDatasetsDraw",
            type: "box",
            yMin: 0,
            yMax: min,
            backgroundColor: "rgba(233, 75, 7, 0.25)",
            borderWidth: 0,
          },
          ok: {
            drawTime: "beforeDatasetsDraw",
            type: "box",
            yMin: min,
            yMax: max,
            backgroundColor: "rgba(250, 250, 5, 0.25)",
            borderWidth: 0,
          },
          good: {
            drawTime: "beforeDatasetsDraw",
            type: "box",
            yMin: max,
            backgroundColor: "rgba(79, 250, 5, 0.25)",
            borderWidth: 0,
          },
        },
      },
    };

    let zValues = [];
    if (!props.isMedian) {
      let biggestValue = Math.max.apply(Math, data.datasets[0].data);
      let smallestValue = Math.min.apply(Math, data.datasets[0].data);
      options.plugins.annotation.annotations[max] = {
        type: "line",
        yMin: biggestValue,
        yMax: biggestValue,
        borderColor: "rgba(0, 0, 0, 1)",
        borderWidth: 1,
      };
      options.plugins.annotation.annotations[min] = {
        type: "line",
        yMin: smallestValue,
        yMax: smallestValue,
        borderColor: "rgba(0, 0, 0, 1)",
        borderWidth: 1,
      };
      zValues = {
        [Math.round(biggestValue * 10) / 10]: translate("sidepanel.graph_max"),
        [Math.round(smallestValue * 10) / 10]: translate("sidepanel.graph_min"),
      };
    } else {
      let median =
        data.datasets[0].data.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        ) / props.yAxis[0].data.length;

      median = Math.round(median * 10) / 10;
      options.plugins.annotation.annotations[median] = {
        type: "line",
        yMin: median,
        yMax: median,
        borderColor: "rgba(0, 0, 0, 1)",
        borderWidth: 1,
      };
      zValues[median] = median;
    }

    max = Math.round(max * 10) / 10;
    min = Math.round(min * 10) / 10;
    options.scales.y = {
      type: "linear",
      display: true,
      position: "left",
      max: 100,
      title: {
        align: "end",
        text: props.band + " in %",
        display: true,
      },
      ticks: {
        stepSize: 20,
      },
    };
    zValues[max] = max;
    zValues[min] = min;
    options.scales.z = {
      type: "linear",
      display: true,
      position: "right",
      max: 100,
      ticks: {
        stepSize: 0.2,
        callback: function (value) {
          if (value in zValues) return zValues[value];
          let iValue = Math.round((value - 0.1) * 10) / 10;
          if (iValue in zValues) return zValues[iValue];
        },
      },
    };
  }
  return (
    <>
      <div onClick={handleShow} className="chart-container">
        {props.showAbs ? (
          <Line data={data} options={options} />
        ) : (
          <Bar data={data} options={options} />
        )}
      </div>
      {show && (
        <Modal
          animation={false}
          show={show}
          size="lg"
          onHide={handleClose}
          centered
        >
          <Modal.Body>
            {props.showAbs ? (
              <Line data={data} options={options} />
            ) : (
              <Bar data={data} options={options} />
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
