import { Component } from 'react';
import './Login.scss';
import { postApi } from "../../services/api/requestApi";
import Modal from 'react-bootstrap/Modal';
import { Link } from "react-router-dom";
import { Translate, I18n, setLocale } from "react-i18nify";

export default class PasswordReset extends Component {

    constructor() {
        super();
        this.state = {
            error: ''
        };
        this.onInputchange = this.onInputchange.bind(this);
    }

    onInputchange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    requestPasswordReset = async(e) => { 
        e.preventDefault();
        let url = '/oauth/forgotPassword'
        let body = {
            'email': this.state.email
        }
        let response = await postApi(url, body);
        if (response.success !== false) {
            console.log(response)
            this.setState({
                success: true,
                error: '',
                header: <Translate value="password_reset.pw_reset_successful_header" />,
                body: <Translate value="password_reset.pw_reset_successful_body" />
            });
        }
        else {
            this.setState({
                error: <Translate value="password_reset.pw_reset_error_email_invalid" />
            })
        }
    }

    render() {
        return (
            <>
                <section className="d-flex justify-content-center align-items-center flex-column login-section">
                    <div>
                        <div className="card login-card">
                            <div className="card-header">
                            <Translate value="password_reset.password_reset" />
                        </div>
                            <div className="card-body">
                                {this.state.error != '' &&
                                <div className='mb-4 bg-warning p-2'> {this.state.error}</div>}
                                <form>
                                    <div className="form-group">
                                        <label><Translate value="password_reset.email" /></label>
                                        <input name="email" type="email" className="form-control"  value={this.state.email} onChange={this.onInputchange}/>
                                    </div>
                                    <div className="d-flex align-items-center login-items">
                                        <Link to="/login" className="btn login-buttons" ><Translate value="general.back" /></Link>
                                        <button className="btn login-buttons" onClick={this.requestPasswordReset.bind(this)}> <Translate value="password_reset.reset" /> </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                <Modal animation={false} show={this.state.success} size="lg" centered>
                    <Modal.Header>
                        {this.state.header}
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.body}
                    </Modal.Body>
                    <Modal.Footer>
                        <Link to="/login" className="btn login-button" ><Translate value="general.login" /></Link>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}