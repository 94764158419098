import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Component } from 'react';
import { postApi } from "../../../services/api/requestApi";

export default class CreateAoi extends Component {

    constructor (props) {
        super(props);
        this.state = {
            name: '',
            type: 'AoI',
            showModal: props.showModal,
            setShowModal: props.setShowModal,
            reload: props.reload
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose = () => {
        this.state.setShowModal('Create', false);
    }

    handleChange (e, type) {
        this.setState({[type]: e.target.value});
    }

    handleSubmit = async() => {
        let url = '/admin/aoi/create';
        let body = {
            'name': this.state.name,
            'type': this.state.type
        }
        let response = await postApi(url, body);
        if (response !== false) {
            this.handleClose();
            this.state.reload();
        }
    }

    render () {
        return (
            <>
                <Modal animation={false} show={this.state.showModal} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Create AoI</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form className="p-4">
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Name" value={this.state.name} onChange={(e) => this.handleChange (e, 'name')}/>
                            </div>
                        </form>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            <button className="btn btn-success mr-3" onClick={this.handleSubmit} type="button">Create</button>
                            <button className="btn btn-danger" onClick={this.handleClose} type="button">Cancel</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }
}