import React from "react";
import Modal from "react-bootstrap/Modal";
import { Component } from "react";
import { postApi } from "../../../services/api/requestApi";
import LoadingIcon from "../../../components/atoms/LoadingIcon";

export default class Log extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      log: [],
    };
    this.updateLog = this.updateLog.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
  }

  scrollToBottom() {
    this.scrollEnd.scrollIntoView({ behavior: "smooth" });
  }

  updateLog = async () => {
    this.setState({ loading: true });
    let response = await postApi("/admin/icscripts/log", { id: this.props.id });
    if (response.success)
      this.setState({ log: response.data.log, loading: false });
    else this.handleClose();
  };

  componentDidMount = async () => {
    this.updateLog();
    this.scrollToBottom();
  };

  componentDidUpdate() {
    this.scrollToBottom();
  }

  handleClose = () => {
    this.props.setShowModal(false);
  };

  render() {
    return (
      <>
        <Modal
          animation={false}
          show={this.props.showModal}
          onHide={this.handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Detailed Log</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.loading ? (
              LoadingIcon()
            ) : (
              <div>
                {this.state.log.map((line) => {
                  return <p>{line}</p>;
                })}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer
            ref={(el) => {
              this.scrollEnd = el;
            }}
          >
            <div className="d-flex">
              <button
                className="btn btn-light"
                onClick={this.updateLog}
                type="button"
              >
                Update
              </button>
              <button
                className="btn btn-light"
                onClick={this.handleClose}
                type="button"
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
