import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Dashboard.scss';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import Dashboard from "../../../utils/Dashboard";
import LoadingIcon from "../../../components/atoms/LoadingIcon";
import CreateAoi from "../aoi/CreateAoi"
import Delete from "../../../utils/Delete"
import UploadPolygons from "../aoi/UploadPolygons"
import ShowPolygons from "../aoi/ShowPolygons";

export default class AoiDashboard extends Dashboard {

    constructor(props) {
        super(props, {
            aois: [],
            showCreateModal: false,
            currentPage: 1,
            contentPerPage: 6,
            content: [],
            currentElem: null,
            currentKey: null,
            loading: true,
            error: false,
            errorMessage: ""
        });
        this.reload = this.reload.bind(this);
        this.setShowModal = this.setShowModal.bind(this);
    }

    componentDidMount = async () => {
        this.reload();
    }

    reload () {
        this.load('aois', '/admin/aois');
    }

    /**
     * This function is called when a button on an element is pressed. All
     * the necessary data is set accordingly
     * 
     * @author Hagen Hoppenstedt
     * @param type {string} The name of the modal that should be shown
     * @param elem {Object} The element data
     */
     setCurrentElement (type, key, elem = null) {
        this.setShowModal(type);
        this.setState({currentElem: elem});
        this.setState({currentKey: key});
    }

    /**
     * Renders the content of the table
     * 
     * @author Hagen Hoppenstedt
     */
    renderAoiData () {
        if (this.state.loading) {
            return (LoadingIcon());
        }
        else {
            let aoiData = [];
            const { SearchBar } = Search;
            const columns = [{
                dataField: "index",
                text: "#",
                headerStyle: { textAlign: "center" },
                style: {textAlign: "center"}
            },
            {
                dataField: "aoi_name",
                text: "Name",
                sort: true,
                headerStyle: { textAlign: "center" },
                style: {textAlign: "center"}
            },
            {
                dataField: "aoi_type",
                text: "Type",
                headerStyle: { textAlign: "center" },
                style: {textAlign: "center"}
            },
            {
                dataField: "scripts",
                text: "Available Scripts",
                headerStyle: { textAlign: "center" },
                style: {textAlign: "center"}
            },
            {
                dataField: "show_polygons",
                style: {textAlign: "center"}
            },
            {
                dataField: "upload_polygons",
                style: {textAlign: "center"}
            },
            {
                dataField: "delete",
                text: "",
                style: {textAlign: "center"},
                headerStyle: { textAlign: "center" }
            }];
            this.state.aois.map ((elem, index) => {
                let currentKey = Object.keys(elem)[0];
                let currentElem = Object.values(elem)[0];
                aoiData.push({
                    index: index + 1,
                    aoi_name: currentElem.aoi_name,
                    aoi_type: currentElem.aoi_type,
                    scripts: currentElem.scripts.join(', '),
                    show_polygons: <button class="btn btn-light btn-sm" disabled={!currentElem.has_polygons} onClick={() => this.setCurrentElement("ShowPolygons", currentKey, currentElem)}>Show Polygons</button>,
                    upload_polygons: <button class="btn btn-dark btn-sm" onClick={() => this.setCurrentElement("UploadPolygons", currentKey, currentElem)}>Upload Polygons</button>,
                    delete: <button class="btn btn-danger btn-sm" onClick={() => this.setCurrentElement("Delete", currentKey, currentElem)}>Delete</button>,
                });
            });
            return (
                <>
                    <ToolkitProvider
                        keyField="id"
                        data={ aoiData }
                        columns={ columns }
                        search
                    >
                    {
                        props => (
                        <div>
                            <SearchBar { ...props.searchProps } />
                            <hr />
                            <BootstrapTable { ...props.baseProps } pagination={paginationFactory({ sizePerPage: 10 })}/>
                        </div>
                        )
                    }
                    </ToolkitProvider>
                    <div>
                        <button id="createAoi" class="btn btn-light create" onClick={() => this.setShowModal('Create')}>New AoI</button>
                    </div>
                </>
            );
        }
    }

    render() {
        let table = [];
        table.push(this.renderAoiData());
        table.push(
            this.state.showCreateModal
            && 
            <CreateAoi reload={this.reload} setShowModal={this.setShowModal} showModal={this.state.showCreateModal}/>
        );
        table.push(
            this.state.showDeleteModal
            && 
            <Delete source="dashboard" name={this.state.currentElem.aoi_name} type="AoI" id={this.state.currentElem.aoi_id} reload={this.reload} setShowModal={this.setShowModal} showModal={this.state.showDeleteModal}/>
        );
        table.push(
            this.state.showUploadPolygonsModal
            && 
            <UploadPolygons aoi={this.state.currentElem} reload={this.reload} setShowModal={this.setShowModal} showModal={this.state.showUploadPolygonsModal}/>
        );
        table.push(
            this.state.showShowPolygonsModal
            &&
            <ShowPolygons aoi={this.state.currentElem} reload={this.reload} setShowModal={this.setShowModal} showModal={this.state.showShowPolygonsModal}/>
        )
        return super.render(table);
    }
}