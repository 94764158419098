import imgde from "../../assets/icons/de.svg";
import imgen from "../../assets/icons/gb.svg";
import React, { useEffect, useState } from "react";
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

export default function ChangeLanguage(props) {

  const de = (
    <>
      <img src={imgde} />
    </>
  );

  const en = (
    <>
      <img src={imgen} />
    </>
  );

  return (
    <>
      <div class="lang-button">
        {isBrowser ? eval(props.locale.toLowerCase()) : null}
        {props.locale.toUpperCase()}
      </div>
    </>
  );
}
