import {getClientSecretApi, getTokenApi} from '../api/authApi'
import {saveToken,checkExpiry} from '../auth/tokenProvider'
import {getApi} from '../api/requestApi'


//todo: cors settings for the server to prevent usage of stolen keywords
//todo: XSS preventation messueres for local storage

export const getClientSecret = async () => {
    let check = await checkStorageSecret();
    
    if (!check) {
        let clientSecret = await getClientSecretApi()
        //error handling 
        //todo: error messages for server not responding, cors, etc
        if(clientSecret) {
            localStorage.setItem('clientId', clientSecret.data.client_id);
            localStorage.setItem('clientSecret', clientSecret.data.client_secret);
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }


}

const checkStorageSecret = async () => {
    let storageClientSecret = localStorage.getItem('clientSecret');
    let storageClientId = localStorage.getItem('clientId');

    if (storageClientSecret == null || storageClientId == null) {
        return false;
    }else {
        return true;
    }    
}

export const login = async (username, password) => {
    let clientSecret = await getClientSecret();
    //todo: check for errors on client generation
    let login = await getTokenApi(username, password, localStorage.getItem('clientId'), localStorage.getItem('clientSecret'));
    if (login.success == true) {
        let storeToken = await saveToken(login);
        //todo: error handling for storage problems
    }else {
        //todo: error handling for auth problems
        if (login.data.error == 'invalid_client') {
            localStorage.removeItem('clientSecret');
            localStorage.removeItem('clientId');
            await getClientSecret();
            login = await getTokenApi(username, password, localStorage.getItem('clientId'), localStorage.getItem('clientSecret'));
        }
    }
    return login.success;
}

export const isLoggedIn = async () => {
    let expiration = await checkExpiry();
    if (expiration) {
        return await getApi('/api/user/get');
    }
    return {
        success: false,
        data: 'Die Sitzung ist ausgelaufen, bitte melden Sie sich erneut an.'
    };

}

export const logout = async () => {
    if (localStorage.getItem('token') !== null || localStorage.getItem('expiry') !== null) {
        localStorage.removeItem('token');
        localStorage.removeItem('expiry');
        window.location.reload();
    }
}