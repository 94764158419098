import React from "react";
import { Form, Row, Col, Alert, Tabs, Tab } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import JSONGenerator from "../../../services/api/JSONGenerator";
import { postApi } from "../../../services/api/requestApi";
import AoiUtils from "./AoiUtils";
import "./Scripts.scss";
import { GEE_URL } from "../../../env";
import Spinner from "react-bootstrap/Spinner";
import CreatableSelect from "react-select/creatable";
import LoadingIcon from "../../../components/atoms/LoadingIcon";

export default class UploadScript extends AoiUtils {
  constructor(props) {
    super(props);
    let settings = props.settings;
    if (!("basemap" in settings.general)) {
      settings.general.basemap = { image_collection: "", opacity: 100 };
    }
    let IC_options = [
      {
        label: "SMI",
        value: "image_collection_moisture",
      },
      {
        label: "SMI AI",
        value: "image_collection_moisture_ai",
      },
    ];
    this.state = {
      id: props.id,
      availableAois: props.availableAois,
      selectedAois: [],
      selectedMemberships: [],
      currentAois: props.currentAois,
      currentMemberships: props.currentMemberships,
      advanced: props.edit,
      alias: props.alias,
      scriptFile: null,
      layers: {},
      edit: props.edit,
      lng: props.settings.general.center.lng,
      lat: props.settings.general.center.lat,
      zoom: props.settings.general.center.zoom,
      showModal: props.showModal,
      setShowModal: props.setShowModal,
      reload: props.reload,
      validated: false,
      selectedLayer: "",
      selectedVisParam: "",
      loading: true,
      main_script: props.main_script,
      currentVisParam: "",
      currentOptions: "",
      currentChartOptions: "",
      error: "",
      settings: settings,
      jsonError: "",
      IC_options: IC_options,
      selectedICPath: {},
      IC_path_options: [{ value: "", label: "No path given" }],
      selectedIC: IC_options[0],
    };

    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateSelectedMemberships = this.updateSelectedMemberships.bind(this);
    this.updateSelectedAois = this.updateSelectedAois.bind(this);
    this.updateSelectedLayer = this.updateSelectedLayer.bind(this);
    this.updateSelectedVisParam = this.updateSelectedVisParam.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderAois = this.renderAois.bind(this);
  }

  componentDidMount = async () => {
    let response = await postApi("/admin/scripts/details", {
      id: this.props.edit ? this.props.id : "",
    });
    let settings = this.props.edit
      ? JSON.parse(response.data.settings)
      : this.state.settings;
    let IC_path_options = response.data.IC_options;
    for (let IC_path of Object.keys(settings.general)) {
      if (IC_path.startsWith("image_collection_")) {
        let value = settings.general[IC_path];
        let option = null;
        if (value !== "") {
          let IC_ID;
          if (value.includes("assets"))
            IC_ID = value.match(/assets\/[^'"]+/)[0].substring(7);
          else if (value.includes('"') | value.includes("'"))
            IC_ID = value.match(/['"].+['"]/)[0];
          else continue;
          option = {
            value,
            label: IC_ID.replaceAll(/["']/g, ""),
          };
          IC_path_options.push(option);
        }

        if (this.state.selectedIC.value === IC_path && option !== null) {
          this.setState({
            selectedICPath: option,
          });
        }
      }
    }

    if (response.success) {
      this.setState({
        lng: settings.general.center.lng,
        lat: settings.general.center.lat,
        zoom: settings.general.center.zoom,
        settings,
        IC_path_options,
      });
    }

    let currentMemberships = [];
    this.state.currentMemberships.forEach((membership) => {
      currentMemberships.push({ label: membership, value: membership });
    });

    let currentAois = [];
    this.state.currentAois.forEach((aoi) => {
      currentAois.push({ label: aoi, value: aoi });
    });

    this.setState({
      selectedMemberships: currentMemberships,
      selectedAois: currentAois,
      loading: false,
    });
  };

  handleClose = () => {
    this.state.setShowModal(this.state.edit ? "Edit" : "Upload", false);
  };

  handleFileChange(e, file) {
    this.setState({
      [file]: e.target.files[0],
      loaded: 0,
    });
    let reader = new FileReader();
    let generator = new JSONGenerator();
    reader.onload = function (e) {
      let result = generator.generateLayerJSON(e.target.result);
      this.setState({
        lng: result.general.center.lng,
        lat: result.general.center.lat,
        zoom: result.general.center.zoom,
        settings: {
          general: { ...result.general, ...this.state.settings.general },
          ...result,
        },
        advanced: true,
      });
      let selectedIC = this.state.selectedIC.value;
      if (selectedIC in result.general) {
        let optFound = false;
        for (let opt of this.state.IC_path_options) {
          if (opt.value === result.general[selectedIC]) {
            this.setState({ selectedICPath: opt });
            optFound = true;
            break;
          }
        }
        if (!optFound) {
          let value = result.general[selectedIC];
          let IC_ID;
          if (value.includes("assets"))
            IC_ID = value.match(/assets\/[^'"]+/)[0].substring(7);
          else IC_ID = value.match(/['"].+['"]/)[0];
          let option = {
            value,
            label: IC_ID.replaceAll(/["']/g, ""),
          };
          this.setState({
            selectedICPath: option,
            IC_path_options: [...this.state.IC_path_options, option],
          });
        }
      }
    }.bind(this);
    reader.readAsText(e.target.files[0]);
  }

  handleChange(e, type, index = "general", visParam = "standard") {
    let settings = this.state.settings;
    let jsonError = "";

    switch (type) {
      case "basemap":
        settings.general.basemap.image_collection = e.target.value;
        this.setState({ settings: settings });
        break;
      case "opacity":
        settings.general.basemap.opacity = e.target.value;
        this.setState({ settings: settings });
        break;
      case "alias":
        this.setState({ alias: e.target.value });
        break;
      case "lng":
        settings.general.center[type] = parseFloat(e.target.value);
        this.setState({ lng: parseFloat(e.target.value), settings: settings });
        break;
      case "lat":
        settings.general.center[type] = parseFloat(e.target.value);
        this.setState({ lat: parseFloat(e.target.value), settings: settings });
        break;
      case "zoom":
        settings.general.center[type] = parseInt(e.target.value);
        this.setState({ zoom: parseInt(e.target.value), settings: settings });
        break;
      case "main_script":
        this.setState({ main_script: e.target.checked });
        break;
      case "visParams":
        try {
          settings.layers[this.state.selectedLayer.value].visParams[visParam] =
            JSON.parse(e.target.value);
        } catch (e) {
          jsonError = e.message;
        }
        this.setState({
          settings: settings,
          currentVisParam: e.target.value,
          jsonError,
        });
        break;
      case "options":
        try {
          settings.layers[this.state.selectedLayer.value].options =
            e.target.value;
        } catch (e) {
          console.log(e);
        }
        this.setState({
          settings: settings,
          currentOptions: e.target.value,
        });
        break;
      case "chart_options":
        try {
          settings.charts[this.state.selectedLayer.value] = JSON.parse(
            e.target.value
          );
        } catch (e) {
          jsonError = e.message;
        }
        this.setState({
          settings: settings,
          currentChartOptions: e.target.value,
          jsonError,
        });
        break;
      case "range":
        settings.layers[this.state.selectedLayer.value][index] = e.target.value;
        this.setState({
          settings: settings,
        });
        break;
      case "advanced":
        this.setState({ advanced: !this.state.advanced });
        break;
      default:
        let value = "value" in e ? e.value : e.target.value;
        settings[index][type] = value;
        if (type.startsWith("image_collection_")) {
          for (let opt of this.state.IC_path_options) {
            if (opt.value === value) {
              this.setState({
                selectedICPath: opt,
              });
              break;
            }
          }
        }
        this.setState({ settings });
        break;
    }

    this.setState({ error: "" });
  }

  handleSubmit = async () => {
    //Push the set memberships into the array
    let memberships = [];
    for (let membership of this.state.selectedMemberships) {
      memberships.push(membership.value);
    }

    let aois = [];
    for (let aoi of this.state.selectedAois) {
      aois.push(aoi.value);
    }

    let imageCollection = this.state.settings.general.image_collection;
    let project_id = "";
    if (imageCollection.includes("projects")) {
      project_id = imageCollection.match(/projects\/[^\/]+/)[0].substring(8);
    }
    if (imageCollection.includes("assets"))
      imageCollection = imageCollection.match(/assets\/[^'"]+/)[0].substring(7);
    else imageCollection = imageCollection.match(/['"].+['"]/)[0];
    let url = this.state.edit
      ? "/admin/scripts/update"
      : "/admin/scripts/upload";
    let body = {
      id: this.state.id,
      alias: this.state.alias,
      layers: JSON.stringify(this.state.settings.layers),
      charts: JSON.stringify(this.state.settings.charts),
      specialLayers: JSON.stringify(this.state.settings.special_layers.layers),
      settings: JSON.stringify(this.state.settings),
      membership: memberships,
      aois: aois,
      main_script: this.state.main_script,
      image_collection: imageCollection,
      project_id,
    };
    let response = await postApi(url, body);
    if (response.success) {
      this.handleClose();
      this.state.reload();
    } else {
      this.setState({
        error: "Upload failed! Make sure your input is valid and try again.",
      });
    }
  };

  verifyScript = async () => {
    console.time("verify");
    this.setState({ loading: true, error: "" });
    try {
      for (let aoi of this.state.selectedAois) {
        let polygons;
        let response = await postApi("/admin/aois/polygons/get", {
          aoi: aoi.value,
        });
        if (response.success) {
          polygons = JSON.parse(response.data.polygons);
        }

        let category = Object.keys(polygons)[0];
        let subpart = null;
        if (!("Polygons" in polygons[category])) {
          subpart = Object.keys(polygons[category].Subparts)[0];
        }
        let layers = Object.keys(this.state.settings.layers);

        await fetch(GEE_URL + "/layer", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dates: { startDate: "", endDate: "" },
            isSpecialLayer: false,
            specialLayers: this.state.layers.spl,
            subpart: subpart,
            geometrics: polygons,
            layers: layers.slice(0, 5),
            selectedGeos: [category],
            isMedianOrDiff: false,
            increaseContrast: false,
            bicubic: false,
            settings: this.state.settings,
            smi: false,
            image_collection: "image_collection",
            initial: true,
          }),
        })
          .then((response) => response.text())
          .then((data) => {
            let formattedData = JSON.parse(data);
            console.log(formattedData);
            let valid = true;
            for (let layer of Object.keys(this.state.settings.layers)) {
              if ((layer === "wqi") | layer.startsWith("smi")) continue;
              if (subpart !== null) {
                if (
                  !(layer in formattedData.mapSubpartLayers.All[subpart]) ||
                  formattedData.mapSubpartLayers.All[subpart][layer].length ===
                    0
                ) {
                  valid = false;
                  throw new Error("Script couldn't be validated");
                }
              } else if (!(layer in formattedData.mapLayers[category])) {
                valid = false;
                throw new Error("Script couldn't be validated");
              }
            }
            if (valid) this.setState({ validated: true, loading: false });
          });
      }
    } catch (e) {
      this.setState({
        validated: false,
        loading: false,
        error:
          "Script could not be verified, please check your input and try again.",
      });
      console.log(e);
    }
    console.timeEnd("verify");
  };

  updateSelectedLayer(selection) {
    let settings = this.state.settings;
    if (!(selection.value in settings.layers) && selection.value !== "spl") {
      settings.layers[selection.value.toLowerCase()] = {
        clear_name: selection.value.toUpperCase(),
        name: selection.value.toLowerCase(),
        description: "",
        options: "",
        min: 0.3,
        max: 0.85,
        ratio: 1,
        visParams: { standard: {} },
      };
      settings.charts[selection.value.toLowerCase()] = {
        clear_name: selection.value.toUpperCase(),
        description: "",
        name: selection.value.toLowerCase(),
        xAxis: "",
        yAxis: "",
      };
    }

    this.setState({
      settings,
      selectedLayer: selection,
      selectedVisParam: "",
      currentVisParam: "",
      currentOptions:
        selection.value !== "spl"
          ? settings.layers[selection.value.toLowerCase()].options
          : "",
      currentChartOptions:
        selection.value !== "spl"
          ? JSON.stringify(
              settings.charts[selection.value.toLowerCase()],
              null,
              2
            )
          : "",
    });
  }

  updateSelectedVisParam(selection) {
    let currentVisParam = "";
    let settings = this.state.settings;

    if (this.state.selectedLayer !== "") {
      if (this.state.selectedLayer.value !== "spl") {
        if (
          !(
            selection.value in
            settings.layers[this.state.selectedLayer.value].visParams
          )
        ) {
          settings.layers[this.state.selectedLayer.value].visParams[
            selection.value
          ] = {};
        }
        currentVisParam = JSON.stringify(
          settings.layers[this.state.selectedLayer.value].visParams[
            selection.value
          ],
          null,
          2
        );
      } else {
        currentVisParam = JSON.stringify(
          settings.special_layers.visParams[selection.value],
          null,
          2
        );
      }
    }
    this.setState({
      settings,
      selectedVisParam: selection,
      currentVisParam,
    });
  }

  addICPathOption = (option) => {
    this.setState({
      IC_path_options: [
        ...this.state.IC_path_options,
        { value: option, label: option },
      ],
    });
  };

  addICOption = (option) => {
    let cleaned_option =
      "image_collection_" +
      option.toLowerCase().replace("smi", "moisture").replaceAll(" ", "_");
    let settings = this.state.settings;
    settings.general[cleaned_option] = "";
    let opt = { value: cleaned_option, label: option };
    this.setState({
      selectedIC: opt,
      IC_options: [...this.state.IC_options, opt],
      settings,
    });
  };

  render() {
    let layers = [];
    for (let layer in this.state.settings.layers) {
      layers.push({ value: layer, label: layer });
    }
    if (layers.length > 0)
      layers.push({ value: "spl", label: "Special Layer" });

    return (
      <>
        <Modal
          size="lg"
          animation={false}
          show={this.state.showModal}
          onHide={this.handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {this.state.edit ? <>Edit script</> : <>Upload Script</>}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.error !== "" && (
              <Alert variant="danger" transition={false}>
                {this.state.error}
              </Alert>
            )}
            {this.state.loading ? (
              <LoadingIcon />
            ) : this.state.validated ? (
              <>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="4">
                      <h3>The script is valid!</h3>
                      <Form.Label>
                        Choose an alias for the script (mandatory)
                      </Form.Label>
                      <Form.Control
                        required
                        type="text"
                        value={this.state.alias}
                        onChange={(e) => this.handleChange(e, "alias")}
                        isValid={this.state.alias !== ""}
                        isInvalid={this.state.error && this.state.alias === ""}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please give a unique alias to the script.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Form>
              </>
            ) : (
              <Form>
                {this.renderAois()}
                <Row className="mb-3">
                  <Form.Group as={Col} className="4">
                    <Form.Label>Longitude</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      value={this.state.lng}
                      onChange={(e) => this.handleChange(e, "lng")}
                      isValid={this.state.lng !== ""}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="4">
                    <Form.Label>Latitude</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      value={this.state.lat}
                      onChange={(e) => this.handleChange(e, "lat")}
                      isValid={this.state.lat !== ""}
                    />
                  </Form.Group>
                  <Form.Group as={Col} className="4">
                    <Form.Label>Zoom</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      value={this.state.zoom}
                      onChange={(e) => this.handleChange(e, "zoom")}
                      isValid={this.state.zoom !== ""}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} className="10">
                    <Form.Label>Bounding Box</Form.Label>
                    <Form.Control
                      required
                      as="textarea"
                      rows={4}
                      value={this.state.settings.general.bbox}
                      onChange={(e) => this.handleChange(e, "bbox")}
                      isValid={/^(Polygon)/.test(
                        this.state.settings.general.bbox
                      )}
                      isInvalid={
                        !/^(Polygon)/.test(this.state.settings.general.bbox) &&
                        this.state.settings.general.bbox !== ""
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      Input has to start with Polygon
                    </Form.Control.Feedback>
                  </Form.Group>
                </Row>
              </Form>
            )}
            {this.state.advanced &&
              !this.state.loading &&
              !this.state.validated && (
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="10">
                      <Form.Label>Path to image collection</Form.Label>
                      <Form.Control
                        value={this.state.settings.general.image_collection}
                        onChange={(e) =>
                          this.handleChange(e, "image_collection")
                        }
                        isValid={/(ee\.ImageCollection\(.+)$/.test(
                          this.state.settings.general.image_collection
                        )}
                        isInvalid={
                          !/(ee\.ImageCollection\(.+)$/.test(
                            this.state.settings.general.image_collection
                          ) &&
                          this.state.settings.general.image_collection !== ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Input has to start with ee.ImageCollection and end with
                        a round bracket.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="4">
                      <Form.Label>Type of Image Collection</Form.Label>
                      <CreatableSelect
                        options={this.state.IC_options}
                        value={this.state.selectedIC}
                        onChange={(selection) => {
                          let settings = this.state.settings;
                          let selectedICPath = {
                            value: "",
                            label: "No path given",
                          };
                          if (selection.value in settings.general) {
                            for (let opt of this.state.IC_path_options) {
                              if (
                                opt.value === settings.general[selection.value]
                              ) {
                                selectedICPath = opt;
                                break;
                              }
                            }
                          } else {
                            settings.general[selection.value] = "";
                          }
                          this.setState({
                            selectedIC: selection,
                            selectedICPath,
                          });
                        }}
                        onCreateOption={this.addICOption}
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="4">
                      <Form.Label>Path to image collection</Form.Label>
                      <CreatableSelect
                        options={this.state.IC_path_options}
                        value={this.state.selectedICPath}
                        onCreateOption={this.addICPathOption}
                        onChange={(e) =>
                          this.handleChange(e, this.state.selectedIC.value)
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Input has to start with ee.ImageCollection and end with
                        a round bracket.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="4">
                      <Form.Label>
                        Path to Base map ImageCollection (optional)
                      </Form.Label>
                      <Form.Control
                        value={
                          this.state.settings.general.basemap.image_collection
                        }
                        onChange={(e) => this.handleChange(e, "basemap")}
                        isValid={/(ee\.ImageCollection\(.+)$/.test(
                          this.state.settings.general.basemap.image_collection
                        )}
                      />
                    </Form.Group>
                    <Form.Group as={Col} className="4">
                      <Form.Label>Base map opacity</Form.Label>
                      <Form.Control
                        type="number"
                        value={this.state.settings.general.basemap.opacity}
                        onChange={(e) => this.handleChange(e, "opacity")}
                        isValid={
                          this.state.settings.general.basemap.opacity !== ""
                        }
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="4">
                      <Form.Label>Reduce region options</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={
                          this.state.settings.general.reduce_region_options
                        }
                        onChange={(e) =>
                          this.handleChange(e, "reduce_region_options")
                        }
                        isValid={/^([\s\S]*\{[\s\S]*\}[\s\S]*)$/.test(
                          this.state.settings.general.reduce_region_options
                        )}
                        isInvalid={
                          !/^([\s\S]*\{[\s\S]*\}[\s\S]*)$/.test(
                            this.state.settings.general.reduce_region_options
                          ) &&
                          this.state.settings.general.reduce_region_options !==
                            ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Input has to start and end with a round and curly
                        Bracket.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} className="4">
                      <Form.Label>Filter options</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={this.state.settings.general.filter_options}
                        onChange={(e) => this.handleChange(e, "filter_options")}
                        isValid={/([/]*\..+[)]+[\s\S]*)+$/.test(
                          this.state.settings.general.filter_options
                        )}
                        isInvalid={
                          !/([/]*\..+[)]+[\s\S]*)+$/.test(
                            this.state.settings.general.filter_options
                          ) && this.state.settings.general.filter_options !== ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Input has to start with a dot and end with two round
                        brackets.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="10">
                      <Form.Label>Water palette</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={this.state.settings.general.water_palette}
                        onChange={(e) => this.handleChange(e, "water_palette")}
                        isValid={/^[\s\S]*\[[\s\S]*\][\s\S]*$/.test(
                          this.state.settings.general.water_palette
                        )}
                        isInvalid={
                          !/^[\s\S]*\[[\s\S]*\][\s\S]*$/.test(
                            this.state.settings.general.water_palette
                          ) && this.state.settings.general.water_palette !== ""
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        Input has to start and end with square Brackets.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} className="4">
                      <CreatableSelect
                        options={layers}
                        value={this.state.selectedLayer}
                        onChange={this.updateSelectedLayer}
                      />
                    </Form.Group>
                  </Row>
                  <Tabs
                    className="mb-3"
                    fill
                    justify
                    defaultActiveKey="vis_params"
                  >
                    {(this.state.selectedLayer.value in
                      this.state.settings.layers) |
                    (this.state.selectedLayer.value === "spl") ? (
                      <Tab eventKey="vis_params" title="Visual Parameters">
                        <Row className="mb-3">
                          <Form.Group as={Col} className="4">
                            <CreatableSelect
                              options={
                                this.state.selectedLayer !== ""
                                  ? this.state.selectedLayer.value === "spl"
                                    ? [{ value: "standard", label: "standard" }]
                                    : Object.keys(
                                        this.state.settings.layers[
                                          this.state.selectedLayer.value
                                        ].visParams
                                      ).map((vp) => {
                                        return { value: vp, label: vp };
                                      })
                                  : []
                              }
                              value={this.state.selectedVisParam}
                              onChange={this.updateSelectedVisParam}
                              disabled={this.state.selectedLayer !== ""}
                            />
                          </Form.Group>
                        </Row>

                        <Row className="mb-3">
                          <Form.Group as={Col} className="10">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              disabled={this.state.selectedVisParam === ""}
                              value={this.state.currentVisParam}
                              onChange={(e) =>
                                this.handleChange(
                                  e,
                                  "visParams",
                                  undefined,
                                  this.state.selectedVisParam.value
                                )
                              }
                              isValid={
                                this.state.jsonError === "" &&
                                this.state.currentVisParam !== ""
                              }
                              isInvalid={this.state.jsonError !== ""}
                            />
                            <Form.Control.Feedback type="invalid">
                              {this.state.jsonError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </Tab>
                    ) : null}

                    {this.state.selectedLayer.value in
                    this.state.settings.layers ? (
                      <Tab eventKey="options" title="Options">
                        <Row className="mb-3">
                          <Form.Group as={Col} className="10">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={this.state.currentOptions}
                              onChange={(e) => this.handleChange(e, "options")}
                              isValid={
                                /^\./.test(this.state.currentOptions) &&
                                this.state.currentOptions !== ""
                              }
                              isInvalid={!/^\./.test(this.state.currentOptions)}
                            />
                            <Form.Control.Feedback type="invalid">
                              Input has to start with a dot.
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </Tab>
                    ) : (
                      this.state.selectedLayer.value === "spl" && (
                        <Tab eventKey="options" title="Options" disabled />
                      )
                    )}

                    {this.state.selectedLayer.value in
                    this.state.settings.layers ? (
                      <Tab eventKey="values" title="Values">
                        <Row className="mb-3">
                          <Form.Group as={Col} className="4">
                            <Form.Label>Minimum</Form.Label>
                            <Form.Control
                              required
                              type="number"
                              disabled={this.state.selectedLayer === ""}
                              value={
                                this.state.selectedLayer !== ""
                                  ? "min" in
                                    this.state.settings.layers[
                                      this.state.selectedLayer.value
                                    ]
                                    ? this.state.settings.layers[
                                        this.state.selectedLayer.value
                                      ].min
                                    : 0.0
                                  : ""
                              }
                              onChange={(e) =>
                                this.handleChange(e, "range", "min")
                              }
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="4">
                            <Form.Label>Maximum</Form.Label>
                            <Form.Control
                              required
                              type="number"
                              disabled={this.state.selectedLayer === ""}
                              value={
                                this.state.selectedLayer !== ""
                                  ? "max" in
                                    this.state.settings.layers[
                                      this.state.selectedLayer.value
                                    ]
                                    ? this.state.settings.layers[
                                        this.state.selectedLayer.value
                                      ].max
                                    : 0.0
                                  : ""
                              }
                              onChange={(e) =>
                                this.handleChange(e, "range", "max")
                              }
                            />
                          </Form.Group>
                          <Form.Group as={Col} className="4">
                            <Form.Label>100 Percent</Form.Label>
                            <Form.Control
                              required
                              type="number"
                              disabled={this.state.selectedLayer === ""}
                              value={
                                this.state.selectedLayer !== ""
                                  ? "ratio" in
                                    this.state.settings.layers[
                                      this.state.selectedLayer.value
                                    ]
                                    ? this.state.settings.layers[
                                        this.state.selectedLayer.value
                                      ].ratio
                                    : 0.0
                                  : ""
                              }
                              onChange={(e) =>
                                this.handleChange(e, "range", "ratio")
                              }
                            />
                          </Form.Group>
                        </Row>
                      </Tab>
                    ) : (
                      this.state.selectedLayer.value === "spl" && (
                        <Tab eventKey="values" title="Values" disabled />
                      )
                    )}

                    {this.state.selectedLayer.value in
                    this.state.settings.layers ? (
                      <Tab eventKey="charts" title="Chart options">
                        <Row className="mb-3">
                          <Form.Group as={Col} className="10">
                            <Form.Control
                              as="textarea"
                              rows={3}
                              value={this.state.currentChartOptions}
                              onChange={(e) =>
                                this.handleChange(e, "chart_options")
                              }
                              isValid={
                                this.state.jsonError === "" &&
                                this.state.currentChartOptions !== ""
                              }
                              isInvalid={this.state.jsonError !== ""}
                            />
                            <Form.Control.Feedback type="invalid">
                              {this.state.jsonError}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </Tab>
                    ) : (
                      this.state.selectedLayer.value === "spl" && (
                        <Tab
                          eventKey="chart_options"
                          title="Chart options"
                          disabled
                        />
                      )
                    )}
                  </Tabs>
                </Form>
              )}
          </Modal.Body>
          <Modal.Footer>
            <div class="script-upload-footer">
              {!this.state.validated ? (
                <>
                  <Form.Control
                    class="form-control form-control-sm"
                    type="file"
                    disabled={this.state.loading}
                    onChange={(e) => this.handleFileChange(e, "scriptFile")}
                  />
                  <button
                    className="btn btn-light mr-3"
                    type="button"
                    disabled={this.state.loading}
                    onClick={(e) => this.handleChange(e, "advanced")}
                  >
                    Advanced
                  </button>
                </>
              ) : (
                <Form.Check
                  type="switch"
                  label="Main script?"
                  checked={this.state.main_script}
                  onChange={(e) => this.handleChange(e, "main_script")}
                />
              )}
              <button
                className="btn btn-dark mr-3"
                type="button"
                onClick={() =>
                  this.state.validated
                    ? this.handleSubmit()
                    : this.verifyScript()
                }
                disabled={
                  this.state.selectedAois.length === 0 ||
                  this.state.selectedMemberships === 0 ||
                  this.state.loading ||
                  (this.state.validated && this.state.alias === "")
                }
              >
                {this.state.validated ? <>Upload</> : <>Verify</>}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
