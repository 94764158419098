import React from 'react';
import Modal from 'react-bootstrap/Modal';
import JSONGenerator from '../../../services/api/JSONGenerator'
import { postApi } from "../../../services/api/requestApi";

export default class UploadPolygons extends React.Component {

    constructor (props) {
        super (props);
        this.state = {
            alias: '',
            aoi: props.aoi,
            polygonFile: null,
            layers: null,
            polygonsSet: false,
            polygons: null,
            showModal: props.showModal,
            setShowModal: props.setShowModal,
            reload: props.reload
        };
        this.handleFileChange = this.handleFileChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose = () => {
        this.state.setShowModal('UploadPolygons', false);
        this.setState({
            alias: '',
            polygonFile: null,
            layers: null,
            polygonsSet: false,
            polygons: null,
        })
    }

    handleFileChange (e, file) {
        this.setState({
            [file]: e.target.files[0],
            loaded: 0
        })
        let reader = new FileReader();
        let generator = new JSONGenerator();
        reader.onload = function(e) {
            this.setState({polygons: generator.generatePolyJSON(e.target.result)});
            this.setState({polygonsSet: true});
        }.bind(this)
        reader.readAsText(e.target.files[0]);
    }

    handleChange (e, type, name = "", field = "") {
        if (type === 'alias') {
            this.setState({
                [type]: e.target.value
            })
        }
        else {
            let temp = this.state.layers;
            temp[type][name][field] = e.target.value;
            this.setState({
                layers: temp
            })
            
        }
    }

    handleSubmit = async() => {
        if (this.state.polygons !== null) {
                
            let url = '/admin/aois/polygons';
            let body = {
                aoi: this.state.aoi.aoi_name,
                json: JSON.stringify(this.state.polygons),
                position: JSON.stringify(this.state.position),
            }
            let response = await postApi(url, body);
            if (response !== false) {
                this.handleClose();
                this.state.reload();
            }

        }
        else {
            alert("Bitte füllen Sie alle Felder aus!");
        }
    }

    renderPolygons () {
        let result = [];
        for (let directory in this.state.polygons) {
            let subparts = [];
            let specialLayers = [];
            for (let subpart in this.state.polygons[directory].Subparts) {
                subparts.push(subpart);
            }
            for (let specialLayer in this.state.polygons[directory].SpecialLayers) {
                specialLayers.push(specialLayer);
            }
            result.push(
                <tr>
                    <td>
                        {this.state.polygons[directory].ClearName}
                    </td>
                    <td>
                        {subparts.join(', ')}
                    </td>
                    <td>
                        {specialLayers.join(', ')}
                    </td>
                </tr>
            );
        }

        return result;

    }

    polygonUpload () {
        return (
            <>
                <Modal animation={false} show={this.state.showModal} onHide={this.handleClose} centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Upload Polygon File</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <input type="file" onChange={(e) => this.handleFileChange(e, 'polygonFile')}/>
                        </div>
                        {
                            this.state.polygonsSet &&
                            <div>
                                <table>
                                    <tr>
                                        <th>Name</th>
                                        <th>Subparts</th>
                                        <th>Special Layers</th>
                                    </tr>
                                    {this.renderPolygons()}
                                </table>
                            </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="d-flex">
                            <button className="btn btn-light mr-3" type="button" onClick={this.handleSubmit} >Upload</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    render () {
        return this.polygonUpload();
    }
}