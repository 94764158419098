import React from "react";
import { Link } from "react-router-dom";
import "./Register.scss";
import Modal from "react-bootstrap/Modal";
import { postApi } from "../../services/api/requestApi";
import { Translate } from "react-i18nify";
import Select from "react-select";

export default class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      zip: "",
      city: "",
      newPassword: "",
      repeatNewPassword: "",
      membership: "Basic",
      role: ["ROLE_USER"],
      locale: "de",
      loading: true,
      success: false,
      error: false,
      errorMessage: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateLocale = this.updateLocale.bind(this);
  }

  handleChange(e, type) {
    if (type === "ROLE_USER" || type === "ROLE_ADMIN")
      this.setState({ role: [type] });
    else this.setState({ [type]: e.target.value });
  }

  handleSubmit = async () => {
    if (this.state.newPassword === this.state.repeatNewPassword) {
      let url = "/oauth/createUser";
      let body = {
        username: this.state.username,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        telephone: this.state.phone,
        address: this.state.address,
        zip: this.state.zip,
        city: this.state.city,
        password: this.state.newPassword,
        membership: this.state.membership,
        roles: this.state.role,
        locale: this.state.locale
      };
      try {
        let response = await postApi(url, body);
        if (response.success) {
          this.setState({ success: true });
        } else this.setState({ error: true });
      } catch (e) {
        this.setState({ error: true });
      }
    } else alert("The password doesn't match");
  };

  handleClose = () => {
    this.setState({ error: false });
  };

  updateLocale(selection) {
    this.setState({ locale: selection.value });
  }

  render() {
    return (
      <>
        <section className="d-flex justify-content-center align-items-center flex-column register-section pl-2 pr-2">
          <div>
            <div className="card register-card mb-4">
              <div className="card-header">
                <Translate value="register.register" />
              </div>
              <div className="card-body">
                <div className="form-group">
                  <label>
                    <Translate value="register.username" />
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={this.state.username}
                    onChange={(e) => this.handleChange(e, "username")}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <Translate value="register.first_name" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.firstName}
                        onChange={(e) => this.handleChange(e, "firstName")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <Translate value="register.last_name" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.lastName}
                        onChange={(e) => this.handleChange(e, "lastName")}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    <Translate value="register.email" />
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={this.state.email}
                    onChange={(e) => this.handleChange(e, "email")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    <Translate value="register.address" />
                  </label>
                  <input
                    type="type"
                    className="form-control"
                    value={this.state.address}
                    onChange={(e) => this.handleChange(e, "address")}
                  />
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <Translate value="register.city" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.city}
                        onChange={(e) => this.handleChange(e, "city")}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>
                        <Translate value="register.zip" />
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={this.state.zip}
                        onChange={(e) => this.handleChange(e, "zip")}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label>
                    <Translate value="register.phone" />
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    value={this.state.phone}
                    onChange={(e) => this.handleChange(e, "phone")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    <Translate value="register.password" />
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={this.state.newPassword}
                    onChange={(e) => this.handleChange(e, "newPassword")}
                  />
                </div>
                <div className="form-group">
                  <label>
                    <Translate value="register.password_repeat" />
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    value={this.state.repeatNewPassword}
                    onChange={(e) => this.handleChange(e, "repeatNewPassword")}
                  />
                </div>
                <div className="form-group">
                  <p>Set preferred language</p>
                  <Select
                    placeholder={this.state.locale}
                    value={this.state.locale}
                    options={[
                      { label: "de", value: "de" },
                      { label: "en", value: "en" },
                    ]}
                    onChange={this.updateLocale}
                  />
                </div>
                <div className="form-group">
                  <label>
                    <Translate value="register.membership" />
                  </label>
                  <select
                    className="form-control"
                    onChange={(e) => this.handleChange(e, "membership")}
                  >
                    <option>Basic</option>
                    <option>Premium</option>
                  </select>
                </div>
                <div className="d-flex register-buttons">
                  <Link to="/login" className="btn login-button">
                    <Translate value="general.back" />
                  </Link>
                  <button
                    className="btn register-button mr-4"
                    onClick={this.handleSubmit.bind(this)}
                  >
                    <Translate value="register.register" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <Modal animation={false} show={this.state.success} size="lg" centered>
            <Modal.Header>
              <Translate value="register.success_header" />
            </Modal.Header>
            <Modal.Body>
              <Translate value="register.success_body" />
            </Modal.Body>
            <Modal.Footer>
              <Link to="/login" className="btn login-button">
                <Translate value="general.login" />
              </Link>
            </Modal.Footer>
          </Modal>
          <Modal
            animation={false}
            show={this.state.error}
            size="lg"
            onHide={this.handleClose}
            centered
          >
            <Modal.Header>
              <Translate value="register.error_header" />
            </Modal.Header>
            <Modal.Body>
              <Translate value="register.error_body" />
            </Modal.Body>
            <Modal.Footer>
              <button
                className="btn btn-primary"
                onClick={this.handleClose}
                type="button"
              >
                OK
              </button>
            </Modal.Footer>
          </Modal>
        </section>
      </>
    );
  }
}
