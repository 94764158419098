import React, { useState } from "react";
import "./EditProfil.scss";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Component } from "react";
import { postApi } from "../../../../services/api/requestApi";
import Select from "react-select";
import { Form, Row, Col, Alert } from "react-bootstrap";

export default class EditProfil extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.id,
      username: props.elem.username,
      email: props.elem.email,
      phone: props.elem.phone,
      address: props.elem.address,
      firstName: props.elem.first_name,
      lastName: props.elem.last_name,
      zip: props.elem.zip,
      city: props.elem.city,
      locale: props.elem.locale,
      currentRole: props.elem.roles,
      notificationsEnabled: props.elem.notifications_enabled,
      smiEnabled: props.elem.smi_enabled,
      aiEnabled: props.elem.ai_enabled,
      newRole: props.elem.roles,
      showModal: props.showModal,
      setShowModal: props.setShowModal,
      source: props.source,
      reload: props.reload,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.updateLocale = this.updateLocale.bind(this);
  }

  handleClose = () => {
    this.state.setShowModal("EditProfil", false);
  };

  handleChange(e, type) {
    if ((type === "ROLE_USER") | (type == "ROLE_ADMIN"))
      this.setState({ newRole: [type] });
    else this.setState({ [type]: e.target.value });
  }

  handleSubmit = async () => {
    let url = "/admin/user/update";
    let body = {
      id: this.state.id,
      email: this.state.email,
      telephone: this.state.phone,
      address: this.state.address,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      zip: this.state.zip,
      city: this.state.city,
      locale: this.state.locale.toLowerCase(),
      roles: this.state.newRole,
      notificationsEnabled: this.state.notificationsEnabled,
      smiEnabled: this.state.smiEnabled,
      aiEnabled: this.state.aiEnabled,
    };
    let response = await postApi(url, body);
    if (response !== false) {
      this.handleClose();
      this.state.reload();
    }
  };

  updateLocale(selection) {
    this.setState({ locale: selection.target.value });
  }

  render(props) {
    return (
      <>
        <Modal
          animation={false}
          show={true}
          size="lg"
          onHide={this.handleClose}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Edit user profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Row className="mb-3">
                <Form.Group as={Col} className="4">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    value={this.state.username}
                    disabled
                  />
                </Form.Group>

                <Form.Group as={Col} className="4">
                  <Form.Label>First name</Form.Label>
                  <Form.Control
                    placeholder="first name"
                    type="text"
                    value={this.state.firstName}
                    onChange={(e) => this.handleChange(e, "firstName")}
                  />
                </Form.Group>
                <Form.Group as={Col} className="4">
                  <Form.Label>Last name</Form.Label>
                  <Form.Control
                    placeholder="last name"
                    type="text"
                    value={this.state.lastName}
                    onChange={(e) => this.handleChange(e, "lastName")}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="4">
                  <Form.Label>E-Mail</Form.Label>
                  <Form.Control
                    placeholder="email"
                    type="email"
                    value={this.state.email}
                    onChange={(e) => this.handleChange(e, "email")}
                  />
                </Form.Group>
                <Form.Group as={Col} className="4">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    placeholder="phone"
                    type="text"
                    value={this.state.phone}
                    onChange={(e) => this.handleChange(e, "phone")}
                  />
                </Form.Group>
                <Form.Group as={Col} className="4">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    placeholder="address"
                    type="text"
                    value={this.state.address}
                    onChange={(e) => this.handleChange(e, "address")}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="4">
                  <Form.Label>ZIP-Code</Form.Label>
                  <Form.Control
                    placeholder="zip"
                    type="text"
                    value={this.state.zip}
                    onChange={(e) => this.handleChange(e, "zip")}
                  />
                </Form.Group>
                <Form.Group as={Col} className="4">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    placeholder="city"
                    type="text"
                    value={this.state.city}
                    onChange={(e) => this.handleChange(e, "city")}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="4">
                  <Form.Select
                    value={this.state.locale}
                    onChange={this.updateLocale}
                  >
                    <option>DE</option>
                    <option>EN</option>
                  </Form.Select>
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} className="4">
                  <Form.Check
                    type="switch"
                    label="Notifications enabled"
                    checked={this.state.notificationsEnabled}
                    onChange={(e) =>
                      this.setState({ notificationsEnabled: e.target.checked })
                    }
                  />
                  <Form.Check
                    type="switch"
                    label="SMI enabled"
                    checked={this.state.smiEnabled}
                    onChange={(e) =>
                      this.setState({ smiEnabled: e.target.checked })
                    }
                  />
                  <Form.Check
                    type="switch"
                    label="AI enhancement enabled"
                    checked={this.state.aiEnabled}
                    onChange={(e) =>
                      this.setState({ aiEnabled: e.target.checked })
                    }
                  />
                </Form.Group>
                <Form.Group as={Col} className="4">
                  <Form.Check
                    inline
                    label="User"
                    type="radio"
                    name="roles"
                    onChange={(e) => this.handleChange(e, "ROLE_USER")}
                    checked={this.state.newRole[0] === "ROLE_USER"}
                  />
                  <Form.Check
                    inline
                    label="Admin"
                    type="radio"
                    name="roles"
                    onChange={(e) => this.handleChange(e, "ROLE_ADMIN")}
                    checked={this.state.newRole[0] === "ROLE_ADMIN"}
                  />
                  <Form.Text>
                    The current role is <b>{this.state.currentRole}</b>
                  </Form.Text>
                </Form.Group>
              </Row>
              <Modal.Footer>
                <div className="d-flex mt-4">
                  <button
                    className="btn btn-primary mr-3"
                    onClick={this.handleSubmit}
                    type="button"
                  >
                    Change
                  </button>
                  <button
                    className="btn btn-primary"
                    onClick={this.handleClose}
                    type="button"
                  >
                    Cancel
                  </button>
                </div>
              </Modal.Footer>
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
