var esprima = require("esprima");

/**
 * This function receives the code from the upload and parses it into a json
 * The json has the following structure:
 *
 * polygon
 *  ClearName
 *  Center
 *  Polygons
 *  Subparts
 *      ClearName
 *      Polygons
 *  SpecialLayer
 *      ClearName
 *      Polygons
 *
 * @param {*} polygons
 * @returns
 */
export default class JSONGenerator {
  /**
   * Calculating the center of the polygon for the correct zoom with this formula:
   * https://de.wikipedia.org/wiki/Geometrischer_Schwerpunkt#Polygon
   *
   * @param {*} points
   */
  calculateCenter = (points) => {
    var center = [];
    let depth = this.getArrayDepth(points);
    if (depth == 4) {
      for (let multiPoly of points) {
        let polygon = multiPoly[0];
        polygon.push(polygon[0]);
        center.push(this.getCenter(polygon));
      }
      center = center[0];
    } else if (depth == 3) {
      let polygon = points[0];
      polygon.push(polygon[0]);
      center = this.getCenter(polygon);
    } else {
      center = this.getCenter(points);
    }

    return {
      lng: center[0],
      lat: center[1],
    };
  };

  getCenter = function (polygon) {
    //Calculate the area
    let a = 0.0;
    for (let i = 0; i < polygon.length - 1; i++) {
      a +=
        parseFloat(polygon[i][0]) * parseFloat(polygon[i + 1][1]) -
        parseFloat(polygon[i + 1][0]) * parseFloat(polygon[i][1]);
    }
    a = a / 2;

    //Calculate the center
    let x = 0.0,
      y = 0.0;
    for (let i = 0; i < polygon.length - 1; i++) {
      y +=
        (polygon[i][1] + polygon[i + 1][1]) *
        (polygon[i][0] * polygon[i + 1][1] - polygon[i + 1][0] * polygon[i][1]);
      x +=
        (polygon[i][0] + polygon[i + 1][0]) *
        (polygon[i][0] * polygon[i + 1][1] - polygon[i + 1][0] * polygon[i][1]);
    }
    return [x / (6 * a), y / (6 * a)];
  };

  getArrayDepth = function (polygons) {
    return Array.isArray(polygons)
      ? 1 + Math.max(...polygons.map((polygon) => this.getArrayDepth(polygon)))
      : 0;
  };

  searchObjectForKey(object, key, isSpecialLayer, path = []) {
    let subdirectory = isSpecialLayer ? object.SpecialLayers : object.Subparts;
    let resultPath = path;
    resultPath.push(isSpecialLayer ? "SpecialLayers" : "Subparts");
    if (key in subdirectory) {
      return resultPath;
    } else if (Object.keys(subdirectory).length > 0) {
      for (let d of subdirectory) {
        resultPath.push(d);
        let result = this.searchObjectForKey(subdirectory[d], key, resultPath);
        if (result != null) return result;
      }
    }
    return null;
  }

  generatePolyJSON = (polygons) => {
    try {
      let polys = {};
      let orphans = [];
      esprima.parseScript(
        polygons,
        {},
        function (declaration, meta) {
          if (declaration.type === "VariableDeclarator") {
            let varName = declaration.id.name;
            varName = varName.replaceAll("$", " ");
            let isSpecialLayer = varName.startsWith("spl_");

            /**
             * Extract the clear name from the variable name
             */
            let splitName = varName.split("_");
            let nameLength = splitName.length;
            let clearName = "";
            let directory = splitName[1];
            let parent = null;
            let layers = ["evi", "gndvi", "ndvi", "rgb"];
            if (nameLength == 2) {
              clearName = splitName[1];
            } else {
              clearName = splitName[3];
              parent = splitName[2];
              if (nameLength == 5) layers = splitName[4].split("/");
            }

            let statement = polygons.substring(
              meta.start.offset,
              meta.end.offset
            );
            let geometryDeclaration = "";
            let coordinates = [];

            /**
             * Check if the statement has a geometric, if not it is only a
             * category
             */
            if (statement.includes("ee.Geometry")) {
              geometryDeclaration = statement.split("ee.Geometry")[1];

              /**
               * If it starts with a point it is a single element else
               * it is a geometry collection
               */
              if (geometryDeclaration.startsWith(".")) {
                geometryDeclaration = geometryDeclaration.split(")")[0];
                coordinates = eval(geometryDeclaration.split("(")[1]);
                geometryDeclaration += ")";
              } else {
                geometryDeclaration = geometryDeclaration
                  .split("(")[1]
                  .split(")")[0];

                let geoObject = eval("(" + geometryDeclaration + ")");

                if ("coordinates" in geoObject) {
                  coordinates = geoObject.coordinates;
                } else {
                  for (let polygon of geoObject.geometries) {
                    coordinates.push(polygon.coordinates);
                  }
                }
              }

              let result = {
                Directory: directory,
                Parent: parent,
                ClearName: clearName,
                Polygons: geometryDeclaration,
                Center: this.calculateCenter(coordinates),
              };
              if (!isSpecialLayer) {
                result = {
                  ...result,
                  LayersToRender: layers,
                  SpecialLayers: {},
                  Subparts: {},
                };
              }

              if (directory in polys) {
                if (parent == null) {
                  polys[directory] = {
                    ...result,
                    Subparts: {
                      ...result.Subparts,
                      ...polys[directory].Subparts,
                    },
                    SpecialLayers: {
                      ...result.SpecialLayers,
                      ...polys[directory].SpecialLayers,
                    },
                  };
                } else if (parent == directory) {
                  if (isSpecialLayer) {
                    polys[parent].SpecialLayers = {
                      ...polys[parent].SpecialLayers,
                      [clearName]: result,
                    };
                  } else {
                    polys[parent].Subparts = {
                      ...polys[parent].Subparts,
                      [clearName]: result,
                    };
                  }
                } else {
                  //TODO: Take care of orphans
                  let path = this.searchObjectForKey(
                    polys[directory],
                    parent,
                    isSpecialLayer
                  );
                  if (path == null) orphans.push(result);
                  else {
                    let objectPath = ".";
                    for (let i = 0; i < path.length - 1; i++) {
                      objectPath += path[i];
                    }
                    eval("polys" + objectPath + " =  result");
                  }
                }
              } else {
                if (parent == null) polys[directory] = result;
                else if (parent == directory) {
                  if (isSpecialLayer) {
                    polys[parent] = {
                      ClearName: parent,
                      SpecialLayers: {
                        [clearName]: result,
                      },
                    };
                  } else {
                    polys[parent] = {
                      ClearName: parent,
                      Subparts: {
                        [clearName]: result,
                      },
                    };
                  }
                } else {
                  orphans.push(result);
                }
              }
            } else {
              polys[directory] = {
                ClearName: clearName,
                Subparts: {},
              };
            }
          }
        }.bind(this)
      );

      return polys;
    } catch (e) {
      console.log(e);
    }
  };

  generateLayerJSON = (script) => {
    let settings;
    let water_palette = [
      "000096",
      "0064ff",
      "00b4ff",
      "33db80",
      "9beb4a",
      "ffeb00",
      "ffb300",
      "ff6400",
      "eb1e00",
      "af0000",
    ];

    try {
      settings = {
        general: {
          center: [],
          image_collection: "",
          bbox: "",
          reduce_region_options: `({
                    reducer: ee.Reducer.percentile([2, 98]),
                    geometry: boundingBox,
                    scale: 2,
                    maxPixels: 3e7,
                  })`,
          water_palette: [
            "000096",
            "0064ff",
            "00b4ff",
            "33db80",
            "9beb4a",
            "ffeb00",
            "ffb300",
            "ff6400",
            "eb1e00",
            "af0000",
          ],
          filter_options: ".sort('system:time_start', false)",
          basemap: {
            image_collection: "",
            opacity: 100,
          },
        },
        layers: {},
        charts: {},
        special_layers: {
          layers: {},
          visParams: {
            standard: { color: "blue", strokeWidth: 0.5, opacity: 0.2 },
          },
        },
      };

      let foundCenter = false;
      let foundReduceRegion = false;

      esprima.parseScript(script, {}, function (declaration, meta) {
        if (!foundCenter) {
          if (declaration.type === "ExpressionStatement") {
            try {
              if (declaration.expression.callee.property.name === "setCenter") {
                settings.general.center = {
                  lng: declaration.expression.arguments[0].value,
                  lat: declaration.expression.arguments[1].value,
                  zoom: declaration.expression.arguments[2].value,
                };
              }
              foundCenter = true;
            } catch (e) {}
          }
        }

        let matches = [...script.matchAll(/var\slayer_([a-zA-Z]+\s)/gm)];
        if (matches !== null)
          for (let layerName of matches) {
            let regex = new RegExp(layerName[0] + "[^;]*");
            let options = regex.exec(script);
            if (options !== null) {
              options = options[0];
              options = options.replace(/([A-Za-z]+.select)/g, "image.select");
              options = options
                .split("resampled")[1]
                .replace(/(\r\n|\n|\r|\s)/gm, "");
              if (options.includes("copyProperties"))
                options = options.split(".copyProperties")[0];
              if (
                (options.match(/\(/g) || []).length <
                (options.match(/\)/g) || []).length
              ) {
                let i = options.lastIndexOf(")");
                options = options.slice(0, i) + options.slice(i + 1);
              }
            } else options = "";

            let name = layerName[1].replace(/\s/gm, "");
            if (!(name in settings.layers))
              settings.layers[name] = {
                ...settings.layers[name],
                name: name,
                clear_name: name.toUpperCase().replaceAll("_", " "),
                description: "",
                options,
              };
          }

        matches = [...script.matchAll(/var\schart_([a-zA-Z]+\s)/gm)];
        if (matches !== null)
          for (let chartName of matches) {
            let name = chartName[1].replace(/\s/gm, "");
            if (!(name in settings.charts))
              settings.charts[name] = {
                ...settings.charts[name],
                name: name,
                clear_name: name.toUpperCase().replaceAll("_", " "),
                description: "",
                xAxis: "",
                yAxis: "",
              };
          }

        matches = [...script.matchAll(/var\sspl_\S+/gm)];
        if (matches !== null)
          for (let splName of matches) {
            let parts = splName[0].split("_");
            let parent = parts[1];
            let name = parts[2];
            if (parent in settings.special_layers.layers)
              if (!(name in settings.special_layers.layers[parent]))
                settings.special_layers.layers[parent] = {
                  [name]: {
                    name: name,
                    clear_name: name.replaceAll("$", " "),
                    description: "",
                  },
                };
          }

        if (declaration.type === "VariableDeclarator") {
          let varName = declaration.id.name;
          let declarationString = script.substring(
            meta.start.offset,
            meta.end.offset
          );

          varName = varName.toLowerCase();

          if (varName.toLowerCase() == "planetic") {
            settings.general.image_collection = declarationString
              .split("=")[1]
              .replace(/(\r\n|\n|\r|\s)/gm, "");
          }
          if (varName.toLowerCase() == "smiic") {
            settings.general.image_collection_moisture =
              declarationString.split("=")[1];
          }
          if (varName.toLowerCase() == "bbox") {
            settings.general.bbox = declarationString.split("ee.Geometry.")[1];
          }
          if (varName == "water_palette") {
            water_palette = eval(declarationString.split("=")[1]);
            settings.general.water_palette = declarationString.split("=")[1];
          }
          if (
            declarationString.includes(".reduceRegion(") &&
            !foundReduceRegion
          ) {
            settings.general.reduce_region_options = declarationString
              .split(".reduceRegion")[1]
              .slice(1, -1);
            foundReduceRegion = true;
          }
          if (varName == "filter") {
            settings.general.filter_options = declarationString
              .split(".filterBounds(BBox)")[1]
              .slice(0, -1);
          }
          if (varName == "visparams") {
            let visParams;
            eval(declarationString);
            for (let layer in visParams) {
              switch (layer) {
                case "spl":
                  settings.special_layers.visParams.standard = visParams[layer];
                  break;
                default:
                  if (layer in settings.layers)
                    settings.layers[layer].visParams = visParams[layer];
                  break;
              }
            }
          }
        }
      });

      return settings;
    } catch (e) {
      console.log(e);
    }
  };
}
